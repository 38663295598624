import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import React, { useEffect, useState } from "react"
import { Button, Form, message, Row, Space, Tabs } from "antd"
import { InfoProduct } from "./InfoProduct"
import { Properties } from "./Properties"
import { Vendors } from "./Vendors"
import { callGetListGroup, callVendorSuggestion } from "@reducer/product.reducer"
import { callCreateProduct } from "@reducer/product-create.reducer"
import { compact, isEmpty, map } from "lodash"
import { IProductRequest } from "@domain/Product"
import IVendor from "@domain/Vendor"
import { useAppSelector } from "@hook/useAppSelector"
import { useNavigate } from "react-router-dom"
import { renderSku } from "@util/Common"
export const CreateProduct: React.FC = () => {
    const [formRef] = Form.useForm()
    const [listCurrentSku, setListCurrentSku] = useState<any>([])
    const [listVendors, setListVendors] = useState<IVendor[]>([])
    const loadingCreate = useAppSelector((state) => state.productCreate.loadingCreate)
    const [currentTab, setCurrentTab] = useState("properties")
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("product.create_title")))
        dispatch(callGetListGroup())
        dispatch(callVendorSuggestion({}))
    }, [dispatch])
    const itemsTab = [
        {
            label: trans("product.property"),
            children: (
                <Properties
                    formRef={formRef}
                    listCurrentSku={listCurrentSku}
                />
            ),
            key: "properties",
        },
        {
            label: trans("product.vendor"),
            children: (
                <Vendors
                    listVendors={listVendors}
                    setListVendors={setListVendors}
                />
            ),
            key: "vendors",
        },
    ]
    const onValuesChange = (changedValues: any) => {
        if (changedValues["properties"] !== undefined) {
            const listProperties = formRef.getFieldValue("properties")
            const finalArr = !isEmpty(listProperties) ? renderSku(listProperties, []) : []
            setListCurrentSku(finalArr)
            formRef.setFieldsValue({
                selected: map(finalArr, "value"),
            })
        }
    }
    const onCreateProduct = (values: IProductRequest) => {
        const dataRequest = {
            name: values.name,
            code: values.code,
            manufactory: values.manufactory,
            productGroupCode: values.productGroupCode,
            description: values.description,
            vendorCodes: !isEmpty(listVendors) ? map(listVendors, "code") : [],
            properties: compact(values.properties)?.filter((iFil) => iFil.attribute && !isEmpty(iFil.value)),
            productSkus: values?.productSkus?.map((item: any, index: number) => ({
                variant: listCurrentSku[index]?.attr,
                isGeneratedSku:item?.checked,
                sku: item?.skuCode
            })),
        }
        dispatch(
            callCreateProduct({
                ...dataRequest,
            })
        ).then((result) => {
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
                navigate("/product")
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    {
                        name: "sidebar.dashboard",
                        link: "/",
                    },
                    {
                        name: "product.title",
                        link: "/product",
                    },
                    {
                        name: "product.create_title",
                        link: "",
                    },
                ]}
            />
            <Form
                onValuesChange={onValuesChange}
                onFinish={(values) =>{
                    if(currentTab === 'vendors'){
                        onCreateProduct(values)
                    }else{
                        setCurrentTab('vendors')
                    }
                }}
                form={formRef}
                initialValues={{
                    properties: Array.from({ length: 3 }),
                }}
                layout="vertical">
                <InfoProduct formRef={formRef} />
                <Tabs
                    activeKey={currentTab}
                    onChange={(key: string) => setCurrentTab(key)}
                    className="nav-space nav-space-x"
                    items={itemsTab}
                />
                <Row
                    justify="end"
                    className="mt-3 mx-3">
                    <Space>
                        <Button
                            loading={loadingCreate}
                            type="default"
                            onClick={() => {
                                navigate("/product")
                            }}>
                            {trans("button.cancel")}
                        </Button>
                        <Button
                            type="primary"
                            loading={loadingCreate}
                            htmlType="submit"
                            className="ml-1">
                            {currentTab === 'properties' ? trans('button.next'): trans("button.save")}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </DefaultLayout>
    )
}
