import { compact, isEmpty, isEqual } from "lodash"
import moment from "moment-timezone"
export const formatDate = (d: any, format: string, zoneInfo?: string) => {
    const timezone = zoneInfo || localStorage.getItem("timezone") || "Asia/Ho_Chi_Minh"
    return moment.utc(d).tz(timezone).format(format)
}

export const formatDateShort = (d: string) => {
    return formatDate(d, "DD/MM/YYYY")
}

export const formatDateMedium = (d: string) => {
    return formatDate(d, "DD/MM/YYYY HH:mm")
}

export const formatDateFull = (d: string) => {
    return formatDate(d, "DD/MM/YYYY HH:mm:ss")
}
export const filterWithTimezone = (d: any) => {
    const timezone = localStorage.getItem("timezone") || "Asia/Ho_Chi_Minh"
    return moment(d).tz(timezone)
}
export const renderSku = (listProperties: any, oldSkus: any) => {
    const oldRenderSku: any = !isEmpty(oldSkus)
        ? oldSkus?.map((iOld: any) => ({
              display: Object.values(iOld?.attr),
              variant: iOld?.attr,
          }))
        : []
    const listFirst = compact(listProperties)
        ?.filter((iFil: any) => iFil?.attribute && !isEmpty(iFil?.value))
        ?.map((item: any) => {
            if (!isEmpty(item.value) && item?.attribute) {
                return item.value?.map((iDetail: string) => ({
                    [item.attribute]: iDetail || "",
                }))
            }
        })
    const convertArr: any = []
    if (!isEmpty(listFirst[0])) {
        listFirst[0]?.forEach((e1: any) => {
            if (!isEmpty(listFirst[1])) {
                listFirst[1]?.forEach((e2: any) => {
                    if (!isEmpty(listFirst[2])) {
                        listFirst[2]?.forEach((e3: any) => {
                            convertArr.push({
                                display: [...Object.values(e1), ...Object.values(e2), ...Object.values(e3)],
                                variant: {
                                    ...e1,
                                    ...e2,
                                    ...e3,
                                },
                            })
                        })
                    } else {
                        convertArr.push({
                            display: [...Object.values(e1), ...Object.values(e2)],
                            variant: {
                                ...e1,
                                ...e2,
                            },
                        })
                    }
                })
            } else {
                convertArr.push({
                    display: [...Object.values(e1)],
                    variant: {
                        ...e1,
                    },
                })
            }
        })
    } else {
        if (!isEmpty(listFirst[1])) {
            listFirst[1]?.forEach((e2: any) => {
                if (!isEmpty(listFirst[2])) {
                    listFirst[2]?.forEach((e3: any) => {
                        convertArr.push({
                            display: [...Object.values(e2), ...Object.values(e3)],
                            variant: {
                                ...e2,
                                ...e3,
                            },
                        })
                    })
                } else {
                    convertArr.push({
                        display: [...Object.values(e2)],
                        variant: {
                            ...e2,
                        },
                    })
                }
            })
        } else {
            if (!isEmpty(listFirst[2])) {
                listFirst[2]?.forEach((e3: any) => {
                    convertArr.push({
                        display: [...Object.values(e3)],
                        variant: {
                            ...e3,
                        },
                    })
                })
            }
        }
    }
    const uniqueArr = !isEmpty(convertArr)
        ? convertArr?.filter((iFil: any) => {
              const itemUnique = !isEmpty(oldRenderSku) ? oldRenderSku?.filter((iOld: any) => isEqual(iFil?.variant, iOld?.variant)) : []
              if (isEmpty(itemUnique)) return iFil
          })
        : []
    const finalArr: any = !isEmpty(uniqueArr)
        ? uniqueArr?.map((item: any, index: number) => ({
              label: item?.display?.join("/"),
              sku: "SKU",
              attr: item.variant,
              value: index + 1,
          }))
        : []
    return !isEmpty(finalArr) ? finalArr : []
}
export const shortenText = (text: string, number: number, suffix?: string) => {
    if (text?.length > number) return `${text.slice(0, number)}...${suffix || ""}`
    return text
}
export const convertFileToBuffer = (file: any) => {
    return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        // Register event listeners
        reader.addEventListener("loadend", (e: any) => resolve(e.target.result))
        reader.addEventListener("error", reject)
        // Read file
        reader.readAsArrayBuffer(file)
    })
}
