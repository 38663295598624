import IVendor from "@domain/Vendor"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callVendorSuggestion } from "@reducer/product.reducer"
import { trans } from "@resources/localization"
import { AutoComplete, Spin } from "antd"
import { debounce, differenceBy, find } from "lodash"
import React, { useMemo } from "react"
type Props = {
    onSelectVendor: (data: any) => void
    onClose: () => void
    listVendors: IVendor[]
}
export const AddVendor: React.FC<Props> = ({ onSelectVendor, onClose, listVendors }) => {
    const listVendorSuggest = useAppSelector((state) => state.product.listVendorSuggest)
    const loadingVendor = useAppSelector((state) => state.product.loadingVendor)
    const dispatch = useAppDispatch()
    const onSearchVendor = (searchText: string) => {
        if (searchText.trim().length >= 3) {
            dispatch(
                callVendorSuggestion({
                    codeOrName: searchText.trim(),
                })
            )
        } else {
            dispatch(callVendorSuggestion({}))
        }
    }
    const options = useMemo(
        () =>
            differenceBy(listVendorSuggest, listVendors, "code")?.map((item) => ({
                value: item?.code,
                key: item?.name,
            })),
        [listVendorSuggest, listVendors]
    )
    return (
        <div className="w-72">
            <AutoComplete
                className="w-full"
                notFoundContent={<Spin spinning={loadingVendor} />}
                allowClear
                onSearch={debounce(onSearchVendor, 1000)}
                placeholder={trans("products.select_vendor")}
                options={options}
                onSelect={(val: any) => {
                    const selectedVendor = find(listVendorSuggest, { code: val }) || {}
                    onSelectVendor(selectedVendor)
                    onClose()
                }}
                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
            />
        </div>
    )
}
