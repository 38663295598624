import { trans } from "@resources/localization"
import { Card, Checkbox, Col, Form, Input, Row, Select, Typography } from "antd"
import { isEmpty } from "lodash"
import "@resources/less/page/product.less"
import React, { useEffect } from "react"

type Props = {
    formRef: any
    listCurrentSku: any
}

export const Properties: React.FC<Props> = ({ formRef, listCurrentSku }) => {
    useEffect(() => {
        formRef.setFieldsValue({
            productSkus: listCurrentSku?.map((item: any) => ({
                checked: true,
                labelCheck: item?.label,
                skuCode: "",
            })),
        })
    }, [listCurrentSku, formRef])
    return (
        <Card className="space-layout">
            <Row
                gutter={30}
                className="mb-2">
                <Col span={12}>
                    <Typography.Text>{trans("product.property")}</Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Text className="text-left w-full">{trans("product.detail_property")}</Typography.Text>
                </Col>
            </Row>
            <Form.List name="properties">
                {(fields) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Row
                                gutter={30}
                                key={key}>
                                <Col span={12}>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(pre, next) => pre?.properties[name]?.value !== next?.properties[name]?.value}>
                                        {({ getFieldValue }) => (
                                            <Form.Item
                                                {...restField}
                                                className="w-full"
                                                name={[name, "attribute"]}
                                                rules={
                                                    !isEmpty(getFieldValue(["properties", name, "value"]))
                                                        ? [
                                                              {
                                                                  required: true,
                                                                  message: trans("message.required"),
                                                              },
                                                          ]
                                                        : []
                                                }>
                                                <Input
                                                    maxLength={16}
                                                    placeholder={trans("product.attribute_name_pl")}
                                                    onChange={(e: any) => {
                                                        const text: string = e.target.value?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g, "")
                                                        const listProperties: any = formRef.getFieldValue("properties")?.slice()
                                                        listProperties[name] = {
                                                            ...listProperties[name],
                                                            attribute: text,
                                                        }
                                                        formRef.setFieldsValue({
                                                            properties: listProperties,
                                                        })
                                                    }}
                                                    onBlur={(e) => {
                                                        const listProperties: any = formRef.getFieldValue("properties")?.slice()
                                                        listProperties[name] = {
                                                            ...listProperties[name],
                                                            attribute: e.target.value.trim(),
                                                        }
                                                        formRef.setFieldsValue({
                                                            properties: listProperties,
                                                        })
                                                    }}
                                                    onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                />
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(pre, next) => pre?.properties[name]?.attribute !== next?.properties[name]?.attribute}>
                                        {({ getFieldValue }) => (
                                            <Form.Item
                                                {...restField}
                                                className="w-full"
                                                rules={
                                                    getFieldValue(["properties", name, "attribute"])
                                                        ? [
                                                              {
                                                                  required: true,
                                                                  message: trans("message.required"),
                                                              },
                                                          ]
                                                        : []
                                                }
                                                name={[name, "value"]}>
                                                <Select
                                                    mode="tags"
                                                    allowClear
                                                    placeholder={trans("product.attribute_name_pl")}
                                                    disabled={!getFieldValue(["properties", name, "attribute"])}
                                                />
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}
                    </>
                )}
            </Form.List>
            <Card title={`${trans("products.select_all")} ${listCurrentSku?.length} ${trans("products.properties")}`}>
                <Form.List name="productSkus">
                    {(fields) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Row
                                    gutter={12}
                                    key={key}>
                                    <Col span={12}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(pre, next) => pre?.productSkus[name]?.labelCheck !== next?.productSkus[name]?.labelCheck}>
                                            {({ getFieldValue }) => (
                                                <Form.Item
                                                    {...restField}
                                                    className="w-full"
                                                    name={[name, "checked"]}
                                                    valuePropName="checked">
                                                    <Checkbox>{getFieldValue(["productSkus", name, "labelCheck"])}</Checkbox>
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            {...restField}
                                            className="w-full"
                                            name={[name, "skuCode"]}>
                                            <Input
                                                placeholder={trans("product.sku_pl")}
                                                onChange={(e) => {
                                                    const newListSkus = formRef.getFieldValue("productSkus")
                                                    newListSkus[name] = {
                                                        ...newListSkus[name],
                                                        skuCode: e.target.value
                                                            ? e.target.value?.trim()?.replace(/[\s’/`~!#*$@%+=,^&(){}[\]|;:"'<>?\\]/g, "")
                                                            : "",
                                                    }
                                                    formRef.setFieldsValue({
                                                        productSkus: newListSkus,
                                                    })
                                                }}
                                                onBlur={(e) => {
                                                    const newListSkus = formRef.getFieldValue("productSkus")
                                                    newListSkus[name] = {
                                                        ...newListSkus[name],
                                                        skuCode: e.target.value
                                                            ? e.target.value?.trim()?.replace(/[\s’/`~!#*$@%+=,^&(){}[\]|;:"'<>?\\]/g, "")
                                                            : "",
                                                    }
                                                    formRef.setFieldsValue({
                                                        productSkus: newListSkus,
                                                    })
                                                }}
                                                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}
                        </>
                    )}
                </Form.List>
            </Card>
        </Card>
    )
}
