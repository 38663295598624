import React from "react"
import { Button, Card, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import { IPaymentStatement } from "@domain/PaymentStatement"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import { PAGINATION } from "@config/constant"
import { formatDateShort, formatDateFull } from "@util/Common"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import SecurityService from "@util/SecurityService"
import { PAYMENT_STATEMENT_SCOPE } from "@config/permission"
import { isEmpty } from "lodash"
export const ListPaymentStatement: React.FC = () => {
    const size: number = useAppSelector((state) => state.PaymentStatement.pagination.size)
    const currentPage: number = useAppSelector((state) => state.PaymentStatement.pagination.current)
    const total: number = useAppSelector((state) => state.PaymentStatement.pagination.total)
    const totalPage: number = useAppSelector((state) => state.PaymentStatement.pagination.totalPage)
    const listPaymentStatement = useAppSelector((state) => state.PaymentStatement.listPaymentStatement)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const loadingList = useAppSelector((state) => state.PaymentStatement.loadingList)

    const columns: ColumnsType<IPaymentStatement> = [
        {
            title: trans("payment_statement.code"),
            className: "bold-400",
            dataIndex: "code",
            width: 100,
            render: (code: string) =>
                SecurityService.can(PAYMENT_STATEMENT_SCOPE.PAYMENT_STATEMENT_DETAIL) ? <Link to={`/payment-statement/${code}`}>{code}</Link> : code,
        },
        {
            title: trans("payment_statement.vendor"),
            className: "bold-400",
            dataIndex: "vendorCode",
            width: 100,
        },
        {
            title: trans("payment_statement.cycle"),
            className: "bold-400",
            dataIndex: "startedAt",
            width: 150,
            render: (text, record: IPaymentStatement) => `${formatDateShort(record?.startedAt) || "---"} - ${formatDateShort(record?.endedAt) || "---"}`,
        },
        {
            title: trans("payment_statement.status"),
            className: "bold-400",
            dataIndex: "status",
            width: 150,
            render: (status) => (!isEmpty(status) ? <Tag color={status?.color}>{trans(`payment_statement.${status?.code}`)}</Tag> : ""),
        },
        {
            title: trans("payment_statement.createdBy"),
            className: "bold-400",
            dataIndex: "createdBy",
            width: 100,
        },
        {
            title: trans("payment_statement.creation_time"),
            className: "bold-400",
            dataIndex: "createdAt",
            width: 150,
            render: (date) => date ? formatDateFull(date) : '',
        },
    ]
    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const param: any = {
            ...oldSearch,
            page: !searchParams.has("size") || Number(oldSearch?.size) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
            size: pageSize,
        }
        setSearchParams(param)
    }
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage} (${total})` : ""}
            extra={
                SecurityService.can(PAYMENT_STATEMENT_SCOPE.PAYMENT_STATEMENT_CREATE) && (
                    <Button
                        type="primary"
                        onClick={() => navigate("/payment-statement/create")}>
                        {trans("payment_statement.add")}
                    </Button>
                )
            }>
            <Table
                rowKey={"id"}
                columns={columns}
                scroll={{
                    x: true,
                }}
                dataSource={listPaymentStatement || []}
                loading={loadingList}
                className="bd-radius-5"
                locale={{
                    emptyText: trans("table.empty"),
                }}
                pagination={{
                    pageSize: size,
                    total: total,
                    current: currentPage,
                    pageSizeOptions: PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS,
                    showSizeChanger: true,
                    onChange: handleChangePage,
                    locale: {
                        items_per_page: `/ ${trans("contact.page_change")}`,
                    },
                }}
            />
        </Card>
    )
}
