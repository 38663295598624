import { IContactDashboard, IDashboardQuery, IOrderDashboard } from "@domain/Dashboard";
import apiClient from "@util/ApiClient";
export default class DashboardApi {
    static getDashboardContacts(params: IDashboardQuery): Promise<{data: IContactDashboard}> {
        return apiClient.get(`statistics/contacts`, { params })
    }
    static getDashboardOrders(params: IDashboardQuery): Promise<{data: IOrderDashboard}> {
        return apiClient.get(`statistics/orders`, { params })
    }
}
