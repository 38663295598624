import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { useAppDispatch } from "@hook/useAppDispatch"
import {
    callCurrenciesSuggest,
    getLinkUrl,
    getTypeFeesOfPaymentStatement,
    callUploadFileBalance,
    callCreatePaymentStatement,
} from "@reducer/paymentStatement.reducer"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callVendorsSuggest } from "@reducer/vendor.reducer"
import { trans } from "@resources/localization"
import { Button, Card, Col, Form, Input, message, Row, Space, Typography } from "antd"
import React, { useEffect } from "react"
import { InfoBalance } from "./InfoPaymentStatement"
import { ListTypeFees } from "./ListTypeFees"
import { UploadOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "@hook/useAppSelector"
import moment from "moment"
import { convertFileToBuffer } from "@util/Common"
import { isEmpty } from "lodash"

export const CreatePaymentStatement: React.FC = () => {
    const [formRef] = Form.useForm()
    const loadingUpload = useAppSelector((state) => state.PaymentStatement.loadingUpload)
    const loadingCreate = useAppSelector((state) => state.PaymentStatement.loadingCreate)
    const loadingUrl = useAppSelector((state) => state.PaymentStatement.loadingGetUrl)
    const loadingType = useAppSelector((state) => state.PaymentStatement.loadingType)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("payment_statement.add")))
        dispatch(callVendorsSuggest({}))
        dispatch(callCurrenciesSuggest())
        dispatch(getTypeFeesOfPaymentStatement())
    }, [dispatch])
    const uploadFileChange = async (files: any) => {
        const newFile = files[0]
        const buffer = await convertFileToBuffer(files[0])
        console.log("newFile", newFile?.type)
        if (
            Number(newFile?.size) <= 10000000 &&
            ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"].includes(newFile?.type)
        ) {
            dispatch(
                getLinkUrl({
                    fileName: newFile?.name,
                })
            ).then(async (result: any) => {
                if (result?.payload?.status === 200) {
                    dispatch(
                        callUploadFileBalance({
                            url: result?.payload?.data?.presignedUrl,
                            body: buffer,
                        })
                    ).then((resultUpload) => {
                        if (resultUpload?.payload?.status === 200) {
                            formRef.setFieldsValue({
                                filename: result?.payload?.data?.name,
                            })
                        } else {
                            message.error(resultUpload?.payload?.response?.data?.message || trans("message.fail"))
                            formRef.setFieldsValue({
                                filename: "",
                            })
                        }
                    })
                }
            })
        } else if (Number(newFile?.size) > 10000000) {
            message.error(trans("payment_statement.exceed"))
        } else if (!["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"].includes(newFile?.type)) {
            message.error(trans("payment_statement.require_file"))
        }
    }
    const onCreate = (values: any) => {
        console.log("values?.paymentStatementItems", values?.paymentStatementItems)
        const fees = values?.paymentStatementItems?.map((smallFee: any) => ({
            id: smallFee?.id,
            code: smallFee?.code,
            parentCode: smallFee?.parent,
            order: smallFee?.order,
            type: smallFee?.type,
            formula: smallFee?.formula,
            amount: isEmpty(smallFee?.children) ? smallFee?.amount : Number(smallFee?.totalParentOriginal),
            conversionAmount: isEmpty(smallFee?.children) ? smallFee?.vietnamAmount : Number(smallFee?.totalParentVND),
            ...(smallFee?.code === "vendor_revenue" && { amount: Number(smallFee?.vendorOriginal) }),
            ...(smallFee?.code === "vendor_revenue" && { conversionAmount: Number(smallFee?.vendorVND) }),
        }))

        const dataRequest: any = {
            ...values,
            startedAt: values?.periodAt ? moment(values?.periodAt[0])?.startOf("day").toISOString() : undefined,
            endedAt: values?.periodAt ? moment(values.periodAt[1])?.endOf("day").toISOString() : undefined,
            paymentStatementItems: fees,
        }
        delete dataRequest.periodAt
        dispatch(callCreatePaymentStatement(dataRequest)).then((result: any) => {
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
                navigate("/payment-statement")
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    return (
        <DefaultLayout loading={loadingType}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    {
                        name: "page.home",
                        link: "/",
                    },
                    {
                        name: "sidebar.payment_statement",
                        link: "/payment-statement",
                    },
                    {
                        name: "payment_statement.add",
                    },
                ]}
            />
            <Form
                form={formRef}
                onFinish={onCreate}
                layout="vertical">
                    <InfoBalance />
                    <ListTypeFees formRef={formRef} />
                    <Card className="space-layout">
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="note"
                                label={trans("contact-detail.event_note")}>
                                <Input.TextArea rows={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="flex items-center">
                        <Typography.Text className="mr-2">{trans("payment_statement.file")}</Typography.Text>
                        <Form.Item
                            hidden
                            name="filename"></Form.Item>
                        <Button
                            loading={loadingUrl || loadingUpload}
                            icon={<UploadOutlined />}
                            onClick={() => document.getElementById("uploadFile")?.click()}>
                            {trans("payment_statement.upload")}
                        </Button>
                        <input
                            id="uploadFile"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            type="file"
                            value=""
                            hidden
                            onChange={(e) => uploadFileChange(e.target.files)}></input>
                        <Form.Item
                            noStyle
                            shouldUpdate={(pre, next) => pre.filename !== next.filename}>
                            {({ getFieldValue }) =>
                                getFieldValue("filename") && (
                                    <div className="flex">
                                        <p className="m-0 mt-1 ml-2">{getFieldValue("filename")}</p>
                                        <Button
                                            type="link"
                                            icon={<i className="fa-solid fa-trash"></i>}
                                            onClick={() => {
                                                formRef.setFieldsValue({
                                                    filename: "",
                                                })
                                            }}></Button>
                                    </div>
                                )
                            }
                        </Form.Item>
                    </div>
                    </Card>
                    <Row
                        justify="end"
                        className="mt-3 mx-3">
                        <Space>
                            <Button
                                type="default"
                                onClick={() => {
                                    navigate("/payment-statement")
                                }}
                                loading={loadingCreate}>
                                {trans("button.cancel")}
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="ml-1"
                                loading={loadingCreate}>
                                {trans("button.save")}
                            </Button>
                        </Space>
                    </Row>
            </Form>
        </DefaultLayout>
    )
}
