import ProductApi from "@api/ProductApi"
import IProduct, { IProductRequest } from "@domain/Product"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    detailProduct: IProduct
    loadingDetail: boolean
    loadingUpdate: boolean
}

const initState: State = {
    detailProduct: {},
    loadingDetail: false,
    loadingUpdate: false,
}

export const callGetDetailProduct = createAsyncThunk("PRODUCT.GET_DETAIL_PRODUCT", async (code: string, thunkApi) => {
    try {
        return await ProductApi.getDetailProduct(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callUpdateProduct = createAsyncThunk("PRODUCT.UPDATE_PRODUCT", async (payload: { code: string; body: IProductRequest }, thunkApi) => {
    try {
        return await ProductApi.updateProduct(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const productUpdateReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetDetailProduct.pending, (state) => {
            state.loadingDetail = true
            state.detailProduct = {}
        })
        .addCase(callGetDetailProduct.fulfilled, (state, { payload }) => {
            state.loadingDetail = false
            state.detailProduct = payload.data
        })
        .addCase(callGetDetailProduct.rejected, (state) => {
            state.loadingDetail = false
            state.detailProduct = {}
        })
    builder
        .addCase(callUpdateProduct.pending, (state) => {
            state.loadingUpdate = true
        })
        .addCase(callUpdateProduct.fulfilled, (state) => {
            state.loadingUpdate = false
        })
        .addCase(callUpdateProduct.rejected, (state) => {
            state.loadingUpdate = false
        })
})
