import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { IContactDashboard, IDashboardOrderQuery, IDashboardQuery, IOrderDashboard } from "@domain/Dashboard"
import DashboardApi from "@api/DashboardApi"

interface State {
    loadingContact: boolean
    yContactDashboard: IContactDashboard
    tContactDashboard: IContactDashboard
    loadingOrder: boolean
    yOrderDashboard: IOrderDashboard
    tOrderDashboard: IOrderDashboard
}

const initState: State = {
    loadingContact: false,
    yContactDashboard: {},
    tContactDashboard: {},
    loadingOrder: false,
    yOrderDashboard: {},
    tOrderDashboard: {},
}

export const callGetContactTodayDashboard = createAsyncThunk("DASHBOARD.GET_CONTACT_TODAT", async (params: IDashboardQuery, thunkApi) => {
    try {
        return await DashboardApi.getDashboardContacts(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetContactYesterdayDashboard = createAsyncThunk("DASHBOARD.GET_CONTACT_YESTERDAY", async (params: IDashboardQuery, thunkApi) => {
    try {
        return await DashboardApi.getDashboardContacts(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetYesterdayOrderDashboard = createAsyncThunk("DASHBOARD.GET_ORDER_YESTERDAY", async (params: IDashboardOrderQuery, thunkApi) => {
    try {
        return await DashboardApi.getDashboardOrders(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetTodayOrderDashboard = createAsyncThunk("DASHBOARD.GET_ORDER_TODAY", async (params: IDashboardOrderQuery, thunkApi) => {
    try {
        return await DashboardApi.getDashboardOrders(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const dashboardReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetContactTodayDashboard.pending, (state) => {
            state.loadingContact = true
            state.tContactDashboard = {}
        })
        .addCase(callGetContactTodayDashboard.fulfilled, (state, { payload }) => {
            state.loadingContact = false
            state.tContactDashboard = payload?.data || {}
        })
        .addCase(callGetContactTodayDashboard.rejected, (state) => {
            state.loadingContact = false
            state.tContactDashboard = {}
        })
    builder
        .addCase(callGetContactYesterdayDashboard.pending, (state) => {
            state.loadingContact = true
            state.yContactDashboard = {}
        })
        .addCase(callGetContactYesterdayDashboard.fulfilled, (state, { payload }) => {
            state.loadingContact = false
            state.yContactDashboard = payload?.data || {}
        })
        .addCase(callGetContactYesterdayDashboard.rejected, (state) => {
            state.loadingContact = false
            state.yContactDashboard = {}
        })
    builder
        .addCase(callGetYesterdayOrderDashboard.pending, (state) => {
            state.loadingOrder = true
            state.yOrderDashboard = {}
        })
        .addCase(callGetYesterdayOrderDashboard.fulfilled, (state, { payload }) => {
            state.loadingOrder = false
            state.yOrderDashboard = payload?.data || {}
        })
        .addCase(callGetYesterdayOrderDashboard.rejected, (state) => {
            state.loadingOrder = false
            state.yOrderDashboard = {}
        })
    builder
        .addCase(callGetTodayOrderDashboard.pending, (state) => {
            state.loadingOrder = true
            state.tOrderDashboard = {}
        })
        .addCase(callGetTodayOrderDashboard.fulfilled, (state, { payload }) => {
            state.loadingOrder = false
            state.tOrderDashboard = payload?.data || {}
        })
        .addCase(callGetTodayOrderDashboard.rejected, (state) => {
            state.loadingOrder = false
            state.tOrderDashboard = {}
        })
})
