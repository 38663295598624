import { trans } from "@resources/localization"

export const PAGINATION: { [key: string]: any } = {
    DEFAULT_CURRENT_PAGE: 1,
    DEFAULT_PAGE_SIZE: 25,
    DEFAULT_TOTAL_ITEM: 0,
    DEFAULT_PAGE_SIZE_OPTIONS: ["25", "50", "100", "150", "200"],
    DEFAULT_TOTAL_PAGE: 1,
}

export const FORMAT_DATE_BEGIN = "YYYY-MM-DD 00:00:00"
export const FORMAT_DATE_END = "YYYY-MM-DD 23:59:59"

interface IBreadcrumb {
    name: string
    link?: string
}

export const HOME_BREADCRUMB: Array<IBreadcrumb> = [
    {
        name: "sidebar.dashboard",
        link: "/",
    },
]

export const VENDORS_BREADCRUMB: Array<IBreadcrumb> = [
    {
        name: "sidebar.dashboard",
        link: "/",
    },
    {
        name: "vendor.title",
        link: "/vendor",
    },
]

export const payment_statement_BREADCRUMB: Array<IBreadcrumb> = [
    {
        name: "sidebar.dashboard",
        link: "/",
    },
    {
        name: "payment_statement.title",
        link: "/payment-statement",
    },
]

export const SUBSCRIBER_BREADCRUMB: Array<IBreadcrumb> = [
    {
        name: "sidebar.dashboard",
        link: "/",
    },
    {
        name: "subscriber.title",
    },
]

export const PRODUCT_BREADCRUMB: Array<IBreadcrumb> = [
    {
        name: "page.home",
        link: "/",
    },
    {
        name: "product.title",
    },
]

export const STATUS_OF_CUSTOMER = [
    { title: trans("subscriber_status.new"), value: "NEW", color: "blue" },
    { title: trans("subscriber_status.processing"), value: "PROCCESSING", color: "orange" },
    { title: trans("subscriber_status.done"), value: "DONE", color: "green" },
    { title: trans("subscriber_status.cancel"), value: "CANCEL", color: "gray" },
]

export const regexSpecial = new RegExp(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g)

export const PRODUCT_STATUS = [
    { key: 0, value: false, title: "Inactive" },
    { key: 1, value: true, title: "Active" }
]