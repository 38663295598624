import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { useAppDispatch } from "@hook/useAppDispatch"
import {
    callCurrenciesSuggest,
    getLinkUrl,
    getTypeFeesOfPaymentStatement,
    callUploadFileBalance,
    getStatusPaymentStatement,
} from "@reducer/paymentStatement.reducer"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callVendorsSuggest } from "@reducer/vendor.reducer"
import { trans } from "@resources/localization"
import { Button, Card, Col, Form, Input, message, Row, Space, Typography } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { InfoBalance } from "./InfoPaymentStatement"
import { ListTypeFees } from "./ListTypeFees"
import { UploadOutlined } from "@ant-design/icons"
import { useNavigate, useParams } from "react-router-dom"
import { useAppSelector } from "@hook/useAppSelector"
import moment from "moment"
import { convertFileToBuffer } from "@util/Common"
import { isEmpty } from "lodash"
import { callUpdateBalance, getDetailBalance } from "@reducer/paymentStatement-detail.reducer"
import appConfig from "@config/app"
import SecurityService from "@util/SecurityService"
import { PAYMENT_STATEMENT_SCOPE } from "@config/permission"
import { AccessDenied } from "@component/AccessDenied"
import { Notfound } from "@component/Notfound"

export const EditPaymentStatement: React.FC = () => {
    const [formRef] = Form.useForm()
    const { id } = useParams()
    const loadingUpload = useAppSelector((state) => state.PaymentStatement.loadingUpload)
    const loadingUrl = useAppSelector((state) => state.PaymentStatement.loadingGetUrl)
    const loadingType = useAppSelector((state) => state.PaymentStatement.loadingType)
    const loadingDetail = useAppSelector((state) => state.balanceDetail.loadingDetail)
    const dataDetail = useAppSelector((state) => state.balanceDetail.dataDetail)
    const loadingUpdate = useAppSelector((state) => state.balanceDetail.loadingUpdate)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [statusShow, setStatusShow] = useState<number>(0)

    const disableUpdate = !SecurityService.can(PAYMENT_STATEMENT_SCOPE.PAYMENT_STATEMENT_UPDATE)

    useEffect(() => {
        dispatch(changePageTitleAction(trans("payment_statement.edit")))
        dispatch(callVendorsSuggest({}))
        dispatch(callCurrenciesSuggest())
        dispatch(getTypeFeesOfPaymentStatement())
        dispatch(getStatusPaymentStatement())
        if (id) {
            dispatch(getDetailBalance(id || "")).then((result: any) => {
                setStatusShow(result?.payload?.response?.status)
            })
        }
    }, [dispatch, id])

    useEffect(() => {
        if (!isEmpty(dataDetail)) {
            formRef.setFieldsValue({
                vendorCode: dataDetail?.vendorCode,
                currencyCode: dataDetail?.currencyCode,
                conversionCurrencyCode: dataDetail?.conversionCurrencyCode,
                periodAt: dataDetail?.startedAt && dataDetail?.endedAt ? [moment(dataDetail?.startedAt), moment(dataDetail?.endedAt)] : [],
                statusCode: dataDetail?.statusCode,
                filename: dataDetail?.fileImportPath,
                note: dataDetail?.note,
            })
        }
    }, [dataDetail])
    const disableStatus = useMemo(() => !isEmpty(dataDetail) && ["reject", "accept", "paid", "completed"]?.includes(dataDetail?.statusCode || ""), [dataDetail])

    const uploadFileChange = async (files: any) => {
        const newFile = files[0]
        const buffer = await convertFileToBuffer(files[0])
        if (
            Number(newFile?.size) <= 10000000 &&
            ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"].includes(newFile?.type)
        ) {
            dispatch(
                getLinkUrl({
                    fileName: newFile?.name,
                })
            ).then(async (result: any) => {
                if (result?.payload?.status === 200) {
                    dispatch(
                        callUploadFileBalance({
                            url: result?.payload?.data?.presignedUrl,
                            body: buffer,
                        })
                    ).then((resultUpload) => {
                        if (resultUpload?.payload?.status === 200) {
                            formRef.setFieldsValue({
                                filename: result?.payload?.data?.name,
                            })
                        } else {
                            message.error(resultUpload?.payload?.response?.data?.message || trans("message.fail"))
                            formRef.setFieldsValue({
                                filename: "",
                            })
                        }
                    })
                }
            })
        } else if (Number(newFile?.size) > 10000000) {
            message.error(trans("payment_statement.exceed"))
        } else if (!["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"].includes(newFile?.type)) {
            message.error(trans("payment_statement.require_file"))
        }
    }

    const onEdit = (values: any) => {
        const fees = values?.paymentStatementItems?.map((smallFee: any) => ({
            id: smallFee?.id,
            code: smallFee?.code,
            parentCode: smallFee?.parent,
            order: smallFee?.order,
            type: smallFee?.type,
            formula: smallFee?.formula,
            amount: isEmpty(smallFee?.children) ? smallFee?.amount : Number(smallFee?.totalParentOriginal),
            conversionAmount: isEmpty(smallFee?.children) ? smallFee?.vietnamAmount : Number(smallFee?.totalParentVND),
            ...(smallFee?.code === "vendor_revenue" && { amount: Number(smallFee?.vendorOriginal) }),
            ...(smallFee?.code === "vendor_revenue" && { conversionAmount: Number(smallFee?.vendorVND) }),
        }))
        const dataRequest: any = {
            ...values,
            startedAt: values?.periodAt ? moment(values?.periodAt[0])?.startOf("day").toISOString() : undefined,
            endedAt: values?.periodAt ? moment(values.periodAt[1])?.endOf("day").toISOString() : undefined,
            paymentStatementItems: fees,
        }
        delete dataRequest.periodAt
        dispatch(
            callUpdateBalance({
                code: dataDetail?.code || "",
                body: dataRequest,
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
                dispatch(getDetailBalance(id || ""))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    return (
        <DefaultLayout loading={loadingDetail || loadingType}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb
                        breadCrumbs={[
                            {
                                name: "page.home",
                                link: "/",
                            },
                            {
                                name: "sidebar.payment_statement",
                                link: "/payment-statement",
                            },
                            {
                                name: `${dataDetail?.code}`,
                            },
                        ]}
                    />
                    <Form
                        form={formRef}
                        onFinish={onEdit}
                        layout="vertical">
                        <InfoBalance />
                        <ListTypeFees formRef={formRef} />
                        <Card className="space-layout">
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="note"
                                        label={trans("contact-detail.event_note")}>
                                        <Input.TextArea
                                            disabled={disableUpdate || disableStatus}
                                            rows={3}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="flex items-center">
                                <Typography.Text className="mr-2">{trans("payment_statement.file")}</Typography.Text>
                                <Form.Item
                                    hidden
                                    name="filename"></Form.Item>
                                <Button
                                    disabled={disableUpdate || disableStatus}
                                    loading={loadingUrl || loadingUpload}
                                    icon={<UploadOutlined />}
                                    onClick={() => document.getElementById("uploadFile")?.click()}>
                                    {trans("payment_statement.upload")}
                                </Button>
                                <input
                                    id="uploadFile"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    type="file"
                                    value=""
                                    hidden
                                    onChange={(e) => uploadFileChange(e.target.files)}></input>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(pre, next) => pre.filename !== next.filename}>
                                    {({ getFieldValue }) =>
                                        getFieldValue("filename") && (
                                            <div className="flex">
                                                <a
                                                    download
                                                    href={`${appConfig.apiUrl}/aws/download?filename=${getFieldValue("filename")}`}
                                                    className="mt-1 mx-2">
                                                    {getFieldValue("filename")}
                                                </a>
                                                <Button
                                                    type="link"
                                                    disabled={disableUpdate || disableStatus}
                                                    icon={<i className="fa-solid fa-trash"></i>}
                                                    onClick={() => {
                                                        formRef.setFieldsValue({
                                                            filename: "",
                                                        })
                                                    }}></Button>
                                            </div>
                                        )
                                    }
                                </Form.Item>
                            </div>
                        </Card>
                        <Row
                            justify="end"
                            className="mt-3 mx-3">
                            <Space>
                                <Button
                                    type="default"
                                    onClick={() => {
                                        navigate("/payment-statement")
                                    }}
                                    loading={loadingUpdate}>
                                    {trans("button.cancel")}
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={disableUpdate}
                                    className="ml-1"
                                    loading={loadingUpdate}>
                                    {trans("button.save")}
                                </Button>
                            </Space>
                        </Row>
                    </Form>
                </>
            )}
        </DefaultLayout>
    )
}
