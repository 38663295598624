import { STATUS_OF_CUSTOMER } from "@config/constant"
import { ISubscriberQuery } from "@domain/Subscriber"
import { trans } from "@resources/localization"
import { Col, Form, Row, Input, Space, Button, Select, DatePicker, Card } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { forEach, isEmpty, omitBy } from "lodash"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { filterWithTimezone } from "@util/Common"
export const FilterSubscriber: React.FC = () => {
    const [formRef] = Form.useForm()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const searchParam = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...searchParam,
                ...(searchParam?.created_at_from &&
                    searchParam?.created_at_to && {
                        date: [filterWithTimezone(searchParam?.created_at_from), filterWithTimezone(searchParam?.created_at_to)],
                    }),
            })
        } else {
            formRef.resetFields()
        }
    }, [searchParams])

    const handleSubmit = (values: ISubscriberQuery) => {
        setSearchParams((prev) => {
            let params: any = {
                ...prev,
                ...values,
                ...(values.date &&
                    values.date[0] && {
                        created_at_from: filterWithTimezone(values.date[0]).startOf('day').toISOString(),
                    }),
                ...(values.date &&
                    values.date[1] && {
                        created_at_to: filterWithTimezone(values.date[1]).endOf('day').toISOString(),
                    }),
            }
            delete params.date
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }

    const onClearSearch = () => {
        setSearchParams({})
        formRef.resetFields()
    }

    return (
        <Card className="space-layout">
        <Form
            form={formRef}
            layout="vertical"
            onFinish={handleSubmit}>
            <Row gutter={12}>
                <Col xs={{span: 24}} sm={{span:12}} md={{span: 12}} lg={{span: 8}}>
                    <Form.Item
                        name="name"
                        label={trans("subscriber.name")}>
                        <Input
                            allowClear
                            placeholder={trans("subscriber.name_filter")}
                            suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)'}} />}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 24}} sm={{span:12}} md={{span: 12}} lg={{span: 8}}>
                    <Form.Item
                        name="email"
                        label={trans("subscriber.email")}>
                        <Input
                            allowClear
                            placeholder={trans("subscriber.email_filter")}
                            suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)'}} />}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 24}} sm={{span:12}} md={{span: 12}} lg={{span: 8}}>
                    <Form.Item
                        name="phone"
                        label={trans("subscriber.phone")}>
                        <Input
                            allowClear
                            placeholder={trans("subscriber.phone_filter")}
                            maxLength={10}
                            suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)'}} />}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 24}} sm={{span:12}} md={{span: 12}} lg={{span: 8}}>
                    <Form.Item
                        name="status"
                        label={trans("subscriber.status")}>
                        <Select
                            allowClear
                            placeholder={trans("subscriber.status_filter")}>
                            {STATUS_OF_CUSTOMER.map((item) => (
                                <Select.Option
                                    key={item.value}
                                    value={item.value}>
                                    {item.title}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={{span: 24}} sm={{span:12}} md={{span: 12}} lg={{span: 8}}>
                    <Form.Item
                        name="date"
                        label={trans("subscriber.created")}>
                        <DatePicker.RangePicker
                            format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                            allowClear
                            placeholder={[trans("order_list.from"), trans("order_list.to")]}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Space>
                    <Button
                        type="primary"
                        className="rounded-md"
                        htmlType="submit">
                        {trans("button.filter")}
                    </Button>
                    <Button
                        type="default"
                        onClick={() => onClearSearch()}>
                        {trans("button.clear_all_filter")}
                    </Button>
                </Space>
            </Row>
        </Form>
        </Card>
    )
}
