import ProductApi from "@api/ProductApi"
import { IProductRequest } from "@domain/Product"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    loadingCreate: boolean
}

const initState: State = {
    loadingCreate: false,
}
export const callCreateProduct = createAsyncThunk("PRODUCT.CREATE", async (payload: IProductRequest, thunkApi) => {
    try {
        return await ProductApi.createProduct(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const productCreateReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callCreateProduct.pending, (state) => {
            state.loadingCreate = true
        })
        .addCase(callCreateProduct.fulfilled, (state) => {
            state.loadingCreate = false
        })
        .addCase(callCreateProduct.rejected, (state) => {
            state.loadingCreate = false
        })
})
