import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { PAGINATION, VENDORS_BREADCRUMB } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callGetCountry, callListVendor } from "@reducer/vendor.reducer"
import { trans } from "@resources/localization"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { CreateVendor } from "./Create"
import { Filter as VendorFilter } from "./Fitler"
import { VendorTable } from "./Table"

export const Vendor: React.FC = () => {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("sidebar.vendor")))
        dispatch(callGetCountry())
    }, [dispatch])
    useEffect(() => {
        dispatch(
            callListVendor({
                ...Object.fromEntries(searchParams.entries()),
                size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
            })
        )
    }, [dispatch, searchParams])
    return (
        <DefaultLayout loading={false}>
                <HeaderAntBreadCrumb breadCrumbs={VENDORS_BREADCRUMB} />
                <VendorFilter />
                <VendorTable />
            <CreateVendor />
        </DefaultLayout>
    )
}
