import { AccessDenied } from "@component/AccessDenied"
import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { Notfound } from "@component/Notfound"
import { PAGINATION, VENDORS_BREADCRUMB } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callGetDetailVendor, callGetListUserOfVendor } from "@reducer/vendor-detail.reducer"
import { trans } from "@resources/localization"
import React, { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { InfoVendor } from "./InfoVendor"
import { ListUser } from "./ListUser"

const DetailVendor = () => {
    const dispatch = useAppDispatch()
    const vendorCode = useParams()?.id as string
    const [searchParams] = useSearchParams()
    const [statusShow, setStatusShow] = useState<number>(0)

    useEffect(() => {
        dispatch(changePageTitleAction(trans("vendor.vendor_detail")))
        dispatch(callGetDetailVendor(vendorCode)).then((result: any) => {
            setStatusShow(result?.payload?.response?.status)
        })
    }, [dispatch])

    useEffect(() => {
        dispatch(
            callGetListUserOfVendor({
                code: vendorCode,
                params: {
                    ...Object.fromEntries(searchParams.entries()),
                    size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                },
            })
        )
    }, [dispatch, searchParams])

    return (
        <DefaultLayout loading={false}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb breadCrumbs={[...VENDORS_BREADCRUMB, { name: trans("vendor.vendor_detail") }]} />
                    <InfoVendor />
                    <ListUser />
                </>
            )}
        </DefaultLayout>
    )
}

export default DetailVendor
