import ProductApi from "@api/ProductApi"
import { PAGINATION } from "@config/constant"
import IProduct, { IProductGroup, IProductQuery } from "@domain/Product"
import IVendor, { IVendorQuery } from "@domain/Vendor"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    pagination: {
        currentPage: number
        size: number
        total: number
        totalPage: number
    }
    listProduct: IProduct[]
    loadingListProduct: boolean
    listGroupProduct: IProductGroup[]
    listVendorSuggest: IVendor[]
    loadingVendor: boolean
    loadingDeleteVendor: boolean
}
const initState: State = {
    pagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    listProduct: [],
    loadingListProduct: false,
    listGroupProduct: [],
    listVendorSuggest: [],
    loadingVendor: false,
    loadingDeleteVendor: false,
}

export const callGetListProduct = createAsyncThunk("PRODUCT.GET_LIST_PRODUCT", async (params: IProductQuery, thunkApi) => {
    try {
        return await ProductApi.getListProduct(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetListGroup = createAsyncThunk("PRODUCT.GET_LIST_GROUP", async (_, thunkApi) => {
    try {
        return await ProductApi.getGroupProduct()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callVendorSuggestion = createAsyncThunk("PRODUCT.GET_VENDOR_SUGGESTION", async (params: IVendorQuery, thunkApi) => {
    try {
        return await ProductApi.getVendorSuggest(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callDeleteVendors = createAsyncThunk("PRODUCT.DELETE_VENDORS", async (payload: { code: string; body: { vendorCodes: string[] } }, thunkApi) => {
    try {
        return await ProductApi.deleteVendors(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const productReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetListProduct.pending, (state) => {
            state.loadingListProduct = true
            state.listProduct = []
        })
        .addCase(callGetListProduct.fulfilled, (state, action) => {
            state.listProduct = action.payload.data ? action.payload.data : []
            state.pagination.currentPage = action.payload.headers["x-page-number"]
                ? Number(action.payload.headers["x-page-number"])
                : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = action.payload.headers["x-page-size"] ? Number(action.payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = action.payload.headers["x-total-count"] ? Number(action.payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = action.payload.headers["x-page-count"] ? Number(action.payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingListProduct = false
        })
        .addCase(callGetListProduct.rejected, (state) => {
            state.loadingListProduct = false
            state.listProduct = []
        })

    builder
        .addCase(callGetListGroup.pending, (state) => {
            state.listGroupProduct = []
        })
        .addCase(callGetListGroup.fulfilled, (state, { payload }) => {
            state.listGroupProduct = payload.data
        })
        .addCase(callGetListGroup.rejected, (state) => {
            state.listGroupProduct = []
        })

    builder
        .addCase(callVendorSuggestion.pending, (state) => {
            state.loadingVendor = true
            state.listVendorSuggest = []
        })
        .addCase(callVendorSuggestion.fulfilled, (state, { payload }) => {
            state.loadingVendor = false
            state.listVendorSuggest = payload.data
        })
        .addCase(callVendorSuggestion.rejected, (state) => {
            state.loadingVendor = false
            state.listVendorSuggest = []
        })
    builder
        .addCase(callDeleteVendors.pending, (state) => {
            state.loadingDeleteVendor = true
        })
        .addCase(callDeleteVendors.fulfilled, (state) => {
            state.loadingDeleteVendor = false
        })
        .addCase(callDeleteVendors.rejected, (state) => {
            state.loadingDeleteVendor = false
        })
})
