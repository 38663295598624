import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import React, { useEffect, useState } from "react"
import { Button, Form, message, Row, Space, Tabs } from "antd"
import { InfoProduct } from "./InfoProduct"
import { Properties } from "./Properties"
import { Vendors } from "./Vendors"
import { callGetListGroup, callVendorSuggestion } from "@reducer/product.reducer"
import { callGetDetailProduct, callUpdateProduct } from "@reducer/product-update.reducer"
import { compact, concat, filter, first, groupBy, isEmpty, map } from "lodash"
import { IProductRequest } from "@domain/Product"
import IVendor from "@domain/Vendor"
import { useAppSelector } from "@hook/useAppSelector"
import { useNavigate, useParams } from "react-router-dom"
import { renderSku } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { PRODUCT_SCOPE } from "@config/permission"
import { AccessDenied } from "@component/AccessDenied"
import { Notfound } from "@component/Notfound"

export const EditProduct: React.FC = () => {
    const navigate = useNavigate()
    const [formRef] = Form.useForm()
    const [listCurrentSku, setListCurrentSku] = useState<any>([])
    const [listVendors, setListVendors] = useState<IVendor[]>([])
    const loadingUpdate = useAppSelector((state) => state.productUpdate.loadingUpdate)
    const dataDetail = useAppSelector((state) => state.productUpdate.detailProduct)
    const loadingDetail = useAppSelector((state) => state.productUpdate.loadingDetail)
    const listVendorSuggest = useAppSelector((state) => state.product.listVendorSuggest)
    const disableEdit = !SecurityService.can(PRODUCT_SCOPE.PRODUCT_UPDATE)
    const [currentTab, setCurrentTab] = useState("properties")
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const [statusShow, setStatusShow] = useState<number>(0)

    useEffect(() => {
        dispatch(changePageTitleAction(trans("products.page_edit_title")))
        dispatch(callVendorSuggestion({}))
        dispatch(callGetListGroup())
        dispatch(callGetDetailProduct(id || "")).then((result: any) => {
            setStatusShow(result?.payload?.response?.status)
        })
    }, [dispatch, id])
    useEffect(() => {
        if (!isEmpty(dataDetail)) {
            const listCurrentProperty = !isEmpty(dataDetail?.property) ? groupBy(dataDetail?.property, "attribute") : []
            const listValues: any[] = !isEmpty(listCurrentProperty) ? Object.values(listCurrentProperty) : []
            const oldProperties = !isEmpty(listValues)
                ? listValues?.map((iPro: any[]) => ({
                      attribute: first(iPro)?.attribute || "",
                      value: map(iPro, "value"),
                      disable: true,
                      oldAttribute: {
                          ...iPro,
                          disable: true,
                      },
                      oldValue: map(iPro, "value"),
                  }))
                : []
            const arrayReplace = Array.from({ length: 3 - listValues.length })?.map(() =>({attribute: ''}))
            const finalProperties = !isEmpty(listValues) ? [...oldProperties, ...arrayReplace] : Array.from({ length: 3 })
            const finalArr = !isEmpty(dataDetail?.productSkus)
                ? dataDetail?.productSkus?.map((item: any, index: number) => ({
                      label: !isEmpty(item?.variant) ? Object.values(item?.variant)?.join("/"): '',
                      id: item?.id,
                      sku: item?.sku,
                      attr: item?.variant,
                      value: index + 1,
                      checked: item?.isGeneratedSku,
                      status: item?.status,
                  }))
                : []
            setListCurrentSku(
                finalArr?.map((iSku: any) => ({
                    ...iSku,
                    disable: true,
                }))
            )
            formRef.setFieldsValue({
                code: dataDetail?.code,
                description: dataDetail?.description,
                isActive: dataDetail?.isActive,
                manufactory: dataDetail?.manufactory,
                name: dataDetail?.name,
                productGroupCode: dataDetail?.productGroupCode,
                properties: finalProperties,
                selected: map(filter(finalArr, { checked: true }), "value"),
            })
            const oldVendorCode = map(dataDetail?.productVendor || [], "vendorCode")
            const listOldVendors: any[] = !isEmpty(listVendorSuggest) ? filter(listVendorSuggest, (iFil: IVendor) => oldVendorCode?.includes(iFil.code)) : []
            setListVendors(
                listOldVendors?.map((iOldVendor: any) => ({
                    ...iOldVendor,
                    status: "old",
                }))
            )
        }
    }, [dataDetail, formRef])
    const itemsTab = [
        {
            label: trans("product.property"),
            children: (
                <Properties
                    formRef={formRef}
                    listCurrentSku={listCurrentSku}
                />
            ),
            key: "properties",
        },
        {
            label: trans("product.vendor"),
            children: (
                <Vendors
                    listVendors={listVendors}
                    setListVendors={setListVendors}
                />
            ),
            key: "vendors",
        },
    ]
    const onValuesChange = (changedValues: any) => {
        if (changedValues["properties"] !== undefined) {
            const listProperties = formRef.getFieldValue("properties")
            const oldListSku = listCurrentSku.filter((oldFil: any) => oldFil?.id)
            const finalArr = !isEmpty(listProperties) ? renderSku(listProperties, oldListSku) : []
            const newArr = !isEmpty(finalArr)
                ? finalArr?.map((iFinal: any) => ({
                      ...iFinal,
                      checked: true,
                      status: true,
                  }))
                : []
            const listNewSku = concat(oldListSku, newArr)?.map((item: any, index: number) => ({
                ...item,
                value: index + 1,
            }))
            setListCurrentSku(listNewSku)
            formRef.setFieldsValue({
                selected: map(filter(listNewSku, { checked: true }), "value"),
            })
        }
    }
    const onUpdate = (values: IProductRequest) => {
        const dataRequest = {
            name: values.name,
            code: values.code,
            isActive: values.isActive,
            manufactory: values.manufactory,
            productGroupCode: values.productGroupCode,
            description: values.description,
            vendorCodes: !isEmpty(listVendors) ? map(listVendors, "code") : [],
            properties: compact(values.properties)?.filter((iFil) => iFil.attribute && !isEmpty(iFil.value)),
            productSkus: values?.productSkus?.map((item: any, index: number) => ({
                id: item?.id,
                sku: item?.skuCode,
                variant: listCurrentSku[index]?.attr,
                isGeneratedSku:item?.checked,
                status: item?.status,
            })),
        }
        dispatch(
            callUpdateProduct({
                code: dataDetail?.code || "",
                body: {
                    ...dataRequest,
                },
            })
        ).then((result) => {
            console.log('result', result)
            if (result?.payload?.status === 200) {
                dispatch(callVendorSuggestion({}))
                dispatch(callGetDetailProduct(id || ""))
                message.success(trans("message.success"))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    return (
        <DefaultLayout loading={loadingDetail}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb
                        breadCrumbs={[
                            {
                                name: "sidebar.dashboard",
                                link: "/",
                            },
                            {
                                name: "product.title",
                                link: "/product",
                            },
                            {
                                name: dataDetail?.name || "",
                                link: "",
                            },
                        ]}
                    />
                    <Form
                        onValuesChange={onValuesChange}
                        onFinish={(values) => {
                            if (currentTab === "vendors") {
                                onUpdate(values)
                            } else {
                                setCurrentTab("vendors")
                            }
                        }}
                        form={formRef}
                        initialValues={{
                            properties: Array.from({ length: 3 }),
                        }}
                        layout="vertical">
                        <InfoProduct formRef={formRef} />
                        <Tabs
                            className="nav-space nav-space-x"
                            items={itemsTab}
                            activeKey={currentTab}
                            onChange={(key) => setCurrentTab(key)}
                        />
                        <Row
                            justify="end"
                            className="mt-3 mx-3">
                            <Space>
                                <Button
                                    loading={loadingUpdate}
                                    type="default"
                                    onClick={() => {
                                        navigate("/product")
                                    }}>
                                    {trans("button.cancel")}
                                </Button>
                                <Button
                                    type="primary"
                                    loading={loadingUpdate}
                                    disabled={disableEdit}
                                    htmlType="submit"
                                    className="ml-1">
                                    {currentTab === "properties" ? trans("button.next") : trans("button.save")}
                                </Button>
                            </Space>
                        </Row>
                    </Form>
                </>
            )}
        </DefaultLayout>
    )
}
