import React from "react"
import { trans } from "@resources/localization"
import { Card, Col, Row, Typography } from "antd"
import { useAppSelector } from "@hook/useAppSelector"
import { formatDateFull } from "@util/Common"
export const InfoVendor = () => {
    const detailVendor = useAppSelector((state) => state.vendorDetail.detailVendor)

    const vendorDomains = (): JSX.Element[] => {
        if (detailVendor?.vendorMapping && Array.isArray(detailVendor?.vendorMapping)) {
            return detailVendor?.vendorMapping?.map((el: { [key: string]: any }, index: number) => {
                return (
                    <div
                        key={index}
                        className={"text-dots"}>
                        <a
                            href={`https://${el?.domain}`}
                            target="_blank"
                            rel="noreferrer">{`https://${el?.domain}`}</a>
                    </div>
                )
            })
        }
        return []
    }

    return (
        <Card className="space-layout" title={detailVendor?.name || ''}>
            <Row>
                <Col xs={24} sm={24} md={8} lg={4}>
                    <Typography.Text className="block">{trans("vendor.code")}</Typography.Text>
                    <Typography.Text
                        className="block"
                        strong>
                        {detailVendor?.code}
                    </Typography.Text>
                </Col>
                <Col xs={24} sm={24} md={8} lg={4}>
                    <Typography.Text className="block">{trans("vendor.administrator")}</Typography.Text>
                    <Typography.Text
                        className="block"
                        strong>
                        {detailVendor?.manager}
                    </Typography.Text>
                </Col>
                <Col xs={24} sm={24} md={8} lg={4}>
                    <Typography.Text className="block">{trans("table.created_by")}</Typography.Text>
                    <Typography.Text
                        className="block"
                        strong>
                        {detailVendor?.createdBy}
                    </Typography.Text>
                </Col>
                <Col xs={24} sm={24} md={8} lg={4}>
                    <Typography.Text className="block">{trans("table.created_at")}</Typography.Text>
                    <Typography.Text
                        className="block"
                        strong>
                        {formatDateFull(detailVendor?.createdAt)}
                    </Typography.Text>
                </Col>
                <Col xs={24} sm={24} md={8} lg={4}>
                    <Typography.Text className="block">{trans("vendor.country")}</Typography.Text>
                    <Typography.Text
                        className="block"
                        strong>
                        {detailVendor?.country?.name}
                    </Typography.Text>
                </Col>
                <Col
                    xs={24} sm={24} md={8} lg={4}
                    className={"ant-col-display-block"}>
                    <p className="block list-unstyled pd-r-5px">{trans("vendor.client_url")}</p>
                    <div className="flex justify-start w-full">{vendorDomains()}</div>
                </Col>
            </Row>
        </Card>
    )
}
