import { PAGINATION, STATUS_OF_CUSTOMER } from "@config/constant"
import { SUBSCRIBER_SCOPE } from "@config/permission"
import { IPaymentStatement } from "@domain/PaymentStatement"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { updateStatusSubscribers } from "@reducer/subscriber.reducer"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import SecurityService from "@util/SecurityService"
import { Pagination, Row, Select, Table, Col, Card } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSearchParams } from "react-router-dom"

export const SubscriberTable: React.FC = () => {
    const size: number = useAppSelector((state) => state.subscriber.pagination.size)
    const current: number = useAppSelector((state) => state.subscriber.pagination.current)
    const total: number = useAppSelector((state) => state.subscriber.pagination.total)
    const listSubscribers = useAppSelector((state) => state.subscriber.listSubscribers)
    const loadingList = useAppSelector((state) => state.subscriber.loadingList)
    const totalPage: number = useAppSelector((state) => state.subscriber.pagination.totalPage)
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useAppDispatch()

    const columns: ColumnsType<any> = [
        {
            title: trans("subscriber.name"),
            className: "bold-400",
            dataIndex: "name",
            width: "25%",
        },
        {
            title: trans("subscriber.email"),
            className: "bold-400",
            dataIndex: "email",
            width: "20%",
        },
        {
            title: trans("subscriber.phone"),
            className: "bold-400",
            dataIndex: "phone",
            width: "20%",
        },
        {
            title: trans("subscriber.status"),
            className: "bold-400",
            dataIndex: "status",
            width: "15%",
            render: (text: string, record: any) => (
                <Select
                    placeholder={trans("subscriber.status")}
                    style={{ width: "125px" }}
                    value={text}
                    disabled={!SecurityService.can(SUBSCRIBER_SCOPE.SUBSCRIBER_UPDATE_STATUS)}
                    onChange={(value) => {
                        dispatch(updateStatusSubscribers({ id: record.id, body: { status: value } }))
                    }}>
                    {STATUS_OF_CUSTOMER.map((item) => (
                        <Select.Option
                            key={item.value}
                            value={item.value}>
                            {item.title}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            title: trans("subscriber.created"),
            className: "bold-400",
            dataIndex: "createdAt",
            width: "20%",
            render: (date: string) => formatDateFull(date || ""),
        },
    ]

    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const param: any = {
            ...oldSearch,
            page: !searchParams.has("size") || Number(oldSearch?.size) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
            size: pageSize,
        }
        setSearchParams(param)
    }
    const expand = (item: IPaymentStatement) => {
        return (
            <Row gutter={8}>
                <Col span={1}></Col>
                <Col span={4}>
                    <p className="font-medium m-0">{trans("subscriber.market")}</p>
                    <p>{item?.market}</p>
                </Col>
                <Col span={4}>
                    <p className="font-medium m-0">{trans("subscriber.quantity")}</p>
                    <p>{item?.quantity}</p>
                </Col>
                <Col span={5}>
                    <p className="font-medium m-0">{trans("subscriber.businessProduct")}</p>
                    <p>{item?.businessProduct}</p>
                </Col>
                <Col span={5}>
                    <p className="font-medium m-0">{trans("subscriber.platform")}</p>
                    <p>{item?.platform}</p>
                </Col>
                <Col span={5}>
                    <p className="font-medium m-0">{trans("subscriber.note")}</p>
                    <p>{item?.note}</p>
                </Col>
            </Row>
        )
    }

    return (
        <Card className="space-layout" title={total !== 0 ? `${trans("contact.page")} ${current}/${totalPage} (${total})` : ""}>
            <Table
                rowKey={"id"}
                columns={columns}
                loading={loadingList}
                scroll={{
                    x: true,
                }}
                dataSource={listSubscribers}
                expandable={{
                    expandedRowRender: (record) => expand(record),
                    rowExpandable: (record) => record.name !== "Not Expandable",
                }}
                className="bd-radius-5"
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
            />
            <Row justify="end">
                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    pageSizeOptions={PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS}
                    defaultCurrent={1}
                    current={current}
                    pageSize={size}
                    total={total}
                    onChange={handleChangePage}
                    locale={{
                        items_per_page: `/ ${trans("contact.page_change")}`,
                    }}
                />
            </Row>
        </Card>
    )
}
