import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons"
import { Avatar, Button, Layout, Menu, Popover, Space, Typography } from "antd"

import lodash, { find, isEmpty } from "lodash"

import IUser from "@domain/User"

import { useAppSelector } from "@hook/useAppSelector"

import vn from "@resources/images/flag/vn.png"
import us from "@resources/images/flag/us.png"
import indonesiaFlagImage from "@resources/images/flag/id.png"
import thailandFlagImage from "@resources/images/flag/th.png"
import DefaultAvatarImg from "@resources/images/avatar-default.png"

import localStore from "@util/LocalStore"
import SecurityService from "@util/SecurityService"
import { trans } from "@resources/localization"
import { TimezoneSetting } from "./TimezoneSetting"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callGetAuthUserInfo, callGetTimezones } from "@reducer/common.reducer"

interface Props {
    collapsed: boolean
    user: IUser | null
    toggle: () => void
    onCollapsed: (val: boolean) => void
}

const languages = [
    { key: "vi", title: "Viet Nam", flag: vn },
    { key: "en", title: "English", flag: us },
    { key: "indo", title: "Indonesia", flag: indonesiaFlagImage },
    { key: "thai", title: "Thailand", flag: thailandFlagImage },
]

export const Header: React.FC<Props> = (props) => {
    const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false)
    const title = useAppSelector((state) => state.common.pageTitle)
    const { id } = useParams()
    const navigate = useNavigate()
    const [isSetting, setIsSetting] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    const handleChangeLang = (lang: string) => {
        localStore.setItem("language", lang)
        window.location.reload()
    }
    const userInfo: any = SecurityService.getUser()
    useEffect(() =>{
        dispatch(callGetAuthUserInfo())
        dispatch(callGetTimezones())
    }, [])

    const renderDropDownUser = () => {
        return (
            <>
                {isSetting ? (
                    <TimezoneSetting goBack={() => setIsSetting((old) => !old)} />
                ) : (
                    <Menu
                        items={[
                            {
                                label:trans("setting.time_zone"),
                                key: "2",
                                icon: <i className="fa-light fa-clock"></i>,
                                onClick: () => setIsSetting((old) => !old),
                            },
                            {
                                label: trans("login.logout_btn"),
                                key: "3",
                                icon: <i className="fa-light fa-right-from-bracket"></i>,
                                onClick: () => navigate("/logout"),
                            },
                        ]}
                    />
                )}
            </>
        )
    }

    const { user, collapsed } = props

    const handleLanguageDropdownVisibleChange = (visible: boolean) => {
        setLanguageDropdownVisible(visible)
    }

    const renderDropDownLang = () => {
        return (
            <>
                {languages.map((item) => (
                    <div
                        key={item.key}
                        className={`px-3 w-32 pointer header-language ${localStore.getItem("language") === item.key ? "bg-gray-200" : ""}`}
                        onClick={() => handleChangeLang(item.key)}>
                        <img
                            className={"image-language inline"}
                            src={item.flag}
                            alt={item.flag}
                        />{" "}
                        {item.title}
                    </div>
                ))}
            </>
        )
    }
    const currentLang: any = useMemo(() => find(languages, { key: localStore.getItem("language") }), [languages, localStore.getItem("language")])

    return (
        <Layout.Header className={`header fixed ${!collapsed ? "collapsed-more-site" : "collapsed-less-site"}`}>
            <div className="header-inner">
                <div className="header-inner-inner h-100pc">
                    <Space>
                        {!collapsed && (
                            <Button
                                type="default"
                                className="btn-collapsed"
                                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                onClick={() => {
                                    props.onCollapsed(!collapsed)
                                }}></Button>
                        )}
                        {title !== "PBlocked" && (
                            <div className="left__header">
                                {id && (
                                    <Button
                                        onClick={() => {
                                            navigate(-1)
                                        }}
                                        type="link"
                                        icon={
                                            <i
                                                className="fa fa-arrow-left mr-2"
                                                aria-hidden="true"></i>
                                        }></Button>
                                )}
                                <b className="title">{title}</b>
                            </div>
                        )}
                    </Space>
                    <div className="right-header flr mr-8">
                        <div className="line-1 mg-l-26 mg-r-26" />
                        <span className="user pointer item">
                            <Popover
                                placement="bottom"
                                trigger="click"
                                overlayClassName={"header-popover-dropdown-user"}
                                content={renderDropDownUser()}>
                                <Avatar
                                    shape="circle"
                                    src={lodash.get(user, "avatar") ? lodash.get(user, "avatar")?.toString() : DefaultAvatarImg}
                                />
                                <Typography.Text
                                    strong
                                    className="ml-1">
                                    {userInfo?.name}
                                </Typography.Text>
                            </Popover>
                            {!isEmpty(currentLang) && (
                                <img
                                    className={"image-language inline"}
                                    src={currentLang?.flag}
                                    alt={currentLang?.flag}
                                />
                            )}
                            <Popover
                                placement="bottom"
                                visible={languageDropdownVisible}
                                onVisibleChange={handleLanguageDropdownVisibleChange}
                                content={renderDropDownLang()}
                                trigger="click">
                                <i className={"fa-solid fa-globe pointer item"} />
                            </Popover>
                        </span>
                    </div>
                </div>
            </div>
        </Layout.Header>
    )
}
