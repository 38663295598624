import { PAGINATION } from "@config/constant"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import { Button, Col, Form, Input, Row, Space, Tabs, Table, Card } from "antd"
import { ColumnsType } from "antd/lib/table"
import TabPane from "antd/lib/tabs/TabPane"
import { forEach, isEmpty, omitBy } from "lodash"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

export const ListUser: React.FC = () => {
    const size: number = useAppSelector((state) => state.vendorDetail.paginationUsers.size)
    const currentPage: number = useAppSelector((state) => state.vendorDetail.paginationUsers.currentPage)
    const total: number = useAppSelector((state) => state.vendorDetail.paginationUsers.total)
    const totalPage: number = useAppSelector((state) => state.vendorDetail.paginationUsers.totalPage)
    const listUsers = useAppSelector((state) => state.vendorDetail.listUsers)
    const loadingListUsers = useAppSelector((state) => state.vendorDetail.loadingListUser)
    const [searchParams, setSearchParams] = useSearchParams()
    const [formRef] = Form.useForm()

    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const oldSearch: any = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...oldSearch,
            })
        } else {
            formRef.resetFields()
        }
    }, [searchParams])

    const handleChangeFilter = (values: { username: string }) => {
        const oldSearch = Object.fromEntries(searchParams.entries())

        setSearchParams(() => {
            let params: any = {
                ...oldSearch,
                ...values,
            }
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }

    const handleChangePage = (page: number, size: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const params: any = {
            ...oldSearch,
            size,
            page: !searchParams.has("size") || Number(oldSearch.size) === size ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
        }
        setSearchParams(params)
    }

    const columns: ColumnsType<any> = [
        {
            title: trans("vendor.username"),
            className: "bold-400",
            dataIndex: "username",
        },
        {
            title: trans("vendor.email"),
            className: "bold-400",
            dataIndex: "user",
            render: (user) => <p className="mb-0">{user.email || ""}</p>,
        },
        {
            title: trans("vendor.added_by"),
            className: "bold-400",
            dataIndex: "addedBy",
        },
        {
            title: trans("vendor.added_at"),
            className: "bold-400",
            dataIndex: "createdAt",
            render: (createdAt) => <p className="mb-0">{formatDateFull(createdAt)}</p>,
        },
    ]

    return (
        <Tabs className="nav-space nav-space-x">
            <TabPane tab={trans("vendor.users")}>
                <Card className="space-layout">
                    <Form
                        form={formRef}
                        onFinish={handleChangeFilter}
                        layout="vertical">
                        <Row gutter={15}>
                            <Col span={24}>
                                <Form.Item
                                    name="username"
                                    label={trans("vendor.username")}>
                                    <Input placeholder={trans("vendor.username")} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Space className="w-full">
                                    <div className="flex justify-start w-full">
                                        <Button
                                            htmlType="submit"
                                            className="mr-2"
                                            loading={loadingListUsers}
                                            type="primary">
                                            {trans("vendor.filter_button")}
                                        </Button>
                                        <Button
                                            type="default"
                                            loading={loadingListUsers}
                                            onClick={() => {
                                                formRef.resetFields()
                                                setSearchParams({})
                                            }}
                                            danger>
                                            {trans("vendor.clear_button")}
                                        </Button>
                                    </div>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Card
                    className="space-layout"
                    title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage}(${total})` : ""}>
                    <Table
                        rowKey={"id"}
                        columns={columns}
                        dataSource={listUsers}
                        loading={loadingListUsers}
                        className="bd-radius-5 mt-5"
                        pagination={{
                            pageSize: size,
                            total: total,
                            current: currentPage,
                            pageSizeOptions: PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS,
                            showSizeChanger: true,
                            onChange: handleChangePage,
                        }}
                    />
                </Card>
            </TabPane>
        </Tabs>
    )
}
