import React from "react"
import { Result } from "antd"
import DefaultLayout from "@component/Layout/Default"
import { trans } from "@resources/localization"

export const Notfound = () => {
    return (
        <DefaultLayout loading={false}>
            <Result
                status="404"
                title="404"
                subTitle={trans("error.error404")}
            />
        </DefaultLayout>
    )
}
