import apiClient from "@util/ApiClient"
import { IPaymentStatement, IPaymentStatementQuery, IPaymentStatementRequest, ICurrency, IStatusPaymentStatement, ITypeFee, IUrlUpload } from "@domain/PaymentStatement"
import { IDetailPaymentStatement } from "@domain/DetailPaymentStatement"
import axios from "axios"

export class PaymentStatementApi {
    static getListPaymentStatement(params: IPaymentStatementQuery): Promise<{
        data: IPaymentStatement[]
        headers: { [key: string]: any }
    }> {
        return apiClient.get(`payment-statements`, { params })
    }
    static getCurrenciesSuggest(): Promise<{ data: ICurrency[] }> {
        return apiClient.get("currencies/autocomplete")
    }
    static getTypeFeesOfPaymentStatement(): Promise<{ data: ITypeFee[] }> {
        return apiClient.get("payment-statements/statements")
    }
    static getStatusesPaymentStatement(): Promise<{ data: IStatusPaymentStatement[] }> {
        return apiClient.get("payment-statements/statuses")
    }
    static getLinkUrlUpload(params: { fileName: string }): Promise<{ data: IUrlUpload }> {
        return apiClient.get("aws/presigned-url", { params })
    }
    static uploadFileBalance(url: string, body: any): Promise<any> {
        return axios({
            url,
            method: "PUT",
            data: body,
        })

    }
    static createPaymentStatement(body: IPaymentStatementRequest): Promise<any> {
        return apiClient.post("payment-statements", body)
    }
    static getDetailPaymentStatement(code: string): Promise<{ data: IDetailPaymentStatement }> {
        return apiClient.get(`payment-statements/${code}`)
    }
    static updatePaymentStatement(code: string, body: IPaymentStatementRequest): Promise<any> {
        return apiClient.patch(`payment-statements/${code}`, body)
    }
}
