import { trans } from "@resources/localization"
import { Card } from "antd"
import React from "react"
type Props = {
    title: string
    count: string | number
    icon: string
    countExtra: string | number
    loading: boolean
    background: string
}
export const SmallDashboard: React.FC<Props> = ({ title, count, background, icon, countExtra, loading }) => {
    return (
        <Card
            bordered={false}
            loading={loading}
            style={{
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow: " #e0e0e080 2px 2px 4px",
            }}>
            <div className="">
                <div className="w-full flex justify-between mb-5">
                    <div className="">
                        <div className="ml-1 mb-3">{title}</div>
                        <div className="text-[#1890ff] text-7xl">{count}</div>
                    </div>
                    <div className={`rounded-full h-0 w-0 md:h-24 md:w-24 ${background} flex justify-center items-center flex-shrink-0`}>
                        <i className={`fa-light ${icon} text-white text-6xl`} />
                    </div>
                </div>
                <hr />
                <div className="h-8">
                    <div className="text-slate-500 mt-5 ">{countExtra !== undefined ? `${trans("dashboard.yesterday")}: ${countExtra}` : ""}</div>
                </div>
            </div>
        </Card>
    )
}
