import IVendor from "@domain/Vendor"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import { Button, Card, Popover, Space, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import React, { useState } from "react"
import { AddVendor } from "./AddVendor"
type Props = {
    listVendors: IVendor[]
    setListVendors: (data: any[]) => void
}
export const Vendors: React.FC<Props> = ({ listVendors, setListVendors }) => {
    const [selectedVendors, setSelectedVendors] = useState<any>([])
    const [open, setOpen] = useState<boolean>(false)
    const columns: ColumnsType<any> = [
        {
            title: trans("products.vendor_code"),
            className: "bold-400",
            dataIndex: "code",
        },
        {
            title: trans("products.vendor_name"),
            className: "bold-400",
            dataIndex: "name",
        },
        {
            title: trans("products.vendor_owner"),
            className: "bold-400",
            dataIndex: "manager",
        },
        {
            title: trans("products.vendor_created_at"),
            className: "bold-400",
            dataIndex: "createdAt",
            render: (date: string) => (date ? formatDateFull(date) : ""),
        },
        {
            title: "",
            className: "bold-400",
            dataIndex: "action",
            width: 100,
            render: (text: string, record: IVendor) => (
                <Button
                    type="link"
                    icon={<i className="fa-solid fa-trash"></i>}
                    onClick={() => {
                        const listFilter = listVendors?.filter((item) => item.id !== record.id)
                        setListVendors(listFilter)
                    }}
                />
            ),
        },
    ]
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedVendors(newSelectedRowKeys)
    }
    return (
        <Card
            className="space-layout"
            extra={
                <Space>
                    <Popover
                        content={
                            open && (
                                <AddVendor
                                    onSelectVendor={(data: IVendor) => {
                                        const listOld = listVendors.slice()
                                        setListVendors([...listOld, data])
                                    }}
                                    listVendors={listVendors}
                                    onClose={() => setOpen(false)}
                                />
                            )
                        }
                        title=""
                        placement="left"
                        trigger="click"
                        open={open}
                        onOpenChange={() => setOpen((old) => !old)}>
                        <Button type="primary">{trans("products.add_vendor")}</Button>
                    </Popover>
                    <Button
                        type="link"
                        icon={<i className="fa-solid fa-trash"></i>}
                        onClick={() => {
                            const listFilter = listVendors?.filter((item) => !selectedVendors.includes(item.id))
                            setListVendors(listFilter)
                        }}
                    />
                </Space>
            }>
            <Table
                rowKey={"id"}
                columns={columns}
                dataSource={listVendors}
                className="bd-radius-5 w-full"
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
                rowSelection={{
                    type: "checkbox",
                    onChange: onSelectChange,
                }}
            />{" "}
        </Card>
    )
}
