import React, { useEffect } from "react"
import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { PAGINATION, PRODUCT_BREADCRUMB } from "@config/constant"
import { Filter as ProductFilter } from "./Filter"
import { ProductTable } from "./Table"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import { callGetListGroup, callGetListProduct } from "@reducer/product.reducer"
import { useSearchParams } from "react-router-dom"

export const Product: React.FC = () => {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("sidebar.product")))
    }, [dispatch])

    useEffect(() => {
        dispatch(callGetListGroup())
        dispatch(
            callGetListProduct({
                ...Object.fromEntries(searchParams.entries()),
                size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
            })
        )
    }, [dispatch, searchParams])

    return (
        <DefaultLayout loading={false}>
                <HeaderAntBreadCrumb breadCrumbs={PRODUCT_BREADCRUMB} />
                <ProductFilter />
                <ProductTable />
        </DefaultLayout>
    )
}
