import { trans } from "@resources/localization"
import { Card, Checkbox, Col, Form, Input, Row, Select, Switch, Typography } from "antd"
import { filter, isEmpty } from "lodash"
import "@resources/less/page/product.less"
import React, { useEffect } from "react"
import SecurityService from "@util/SecurityService"
import { PRODUCT_SCOPE } from "@config/permission"

type Props = {
    formRef: any
    listCurrentSku: any
}

export const Properties: React.FC<Props> = ({ formRef, listCurrentSku }) => {
    const disableEdit = !SecurityService.can(PRODUCT_SCOPE.PRODUCT_UPDATE)
    useEffect(() => {
        const oldData = !isEmpty(listCurrentSku)
            ? listCurrentSku?.map((item: any) => ({
                  id: item?.id || undefined,
                  oldChecked: item?.checked || false,
                  checked: item?.checked || false,
                  status: item?.status || false,
                  labelCheck: item?.label,
                  oldSku: item?.sku || "",
                  skuCode: item?.disable ? item?.sku : "",
                  disable: item?.disable,
              }))
            : []
        formRef.setFieldsValue({
            productSkus: oldData,
        })
    }, [listCurrentSku, formRef])
    return (
        <Card className="space-layout">
            <Row
                gutter={30}
                className="mb-2">
                <Col span={12}>
                    <Typography.Text>{trans("product.property")}</Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Text className="text-left w-full">{trans("product.detail_property")}</Typography.Text>
                </Col>
            </Row>
            <Form.List name="properties">
                {(fields) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Row
                                gutter={30}
                                key={key}>
                                <Col span={12}>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(pre, next) => pre?.properties[name]?.value !== next?.properties[name]?.value}>
                                        {({ getFieldValue }) => (
                                            <Form.Item
                                                {...restField}
                                                className="w-full"
                                                name={[name, "attribute"]}
                                                rules={
                                                    !isEmpty(getFieldValue(["properties", name, "value"]))
                                                        ? [
                                                              {
                                                                  required: true,
                                                                  message: trans("message.required"),
                                                              },
                                                          ]
                                                        : []
                                                }>
                                                <Input
                                                    maxLength={16}
                                                    placeholder={trans("product.attribute_name_pl")}
                                                    onChange={(e: any) => {
                                                        const text: string = e.target.value?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g, "")
                                                        const listProperties: any = formRef.getFieldValue("properties")?.slice()
                                                        listProperties[name] = {
                                                            ...listProperties[name],
                                                            attribute: text,
                                                        }
                                                        formRef.setFieldsValue({
                                                            properties: listProperties,
                                                        })
                                                    }}
                                                    disabled={disableEdit || getFieldValue(["properties", name, "disable"])}
                                                    onBlur={(e) => {
                                                        const listProperties: any = formRef.getFieldValue("properties")?.slice()
                                                        listProperties[name] = {
                                                            ...listProperties[name],
                                                            attribute: e.target.value.trim(),
                                                        }
                                                        formRef.setFieldsValue({
                                                            properties: listProperties,
                                                        })
                                                    }}
                                                    onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                />
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(pre, next) => pre?.properties[name]?.attribute !== next?.properties[name]?.attribute}>
                                        {({ getFieldValue }) => (
                                            <Form.Item
                                                {...restField}
                                                className="w-full"
                                                rules={
                                                    getFieldValue(["properties", name, "attribute"])
                                                        ? [
                                                              {
                                                                  required: true,
                                                                  message: trans("message.required"),
                                                              },
                                                          ]
                                                        : []
                                                }
                                                name={[name, "value"]}>
                                                <Select
                                                    mode="tags"
                                                    placeholder={trans("product.attribute_name_pl")}
                                                    disabled={disableEdit || !getFieldValue(["properties", name, "attribute"])}>
                                                    {formRef.getFieldValue(["properties", name, "value"])?.map((item: string, index: any) => (
                                                        <Select.Option
                                                            key={index}
                                                            value={item}
                                                            disabled={
                                                                !isEmpty(filter(formRef.getFieldValue(["properties", name, "oldAttribute"]), { value: item }))
                                                            }>
                                                            {item}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}
                    </>
                )}
            </Form.List>
            {!isEmpty(listCurrentSku) && (
                <Card title={`${trans("products.select_all")} ${listCurrentSku?.length} ${trans("products.properties")}`}>
                    <Form.List name="productSkus">
                        {(fields) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Row
                                        gutter={30}
                                        key={key}>
                                        <Col span={10}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(pre, next) => pre?.productSkus[name] !== next?.productSkus[name]}>
                                                {({ getFieldValue }) => (
                                                    <Form.Item
                                                        {...restField}
                                                        className="w-full"
                                                        name={[name, "checked"]}
                                                        valuePropName="checked">
                                                        <Checkbox
                                                            disabled={
                                                                disableEdit ||
                                                                (getFieldValue(["productSkus", name, "disable"]) &&
                                                                    getFieldValue(["productSkus", name, "oldChecked"]))
                                                            }>
                                                            {getFieldValue(["productSkus", name, "labelCheck"])}
                                                        </Checkbox>
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                {...restField}
                                                className="w-full"
                                                name={[name, "skuCode"]}>
                                                <Input
                                                    placeholder={trans("product.sku_pl")}
                                                    disabled={
                                                        disableEdit ||
                                                        (formRef.getFieldValue(["productSkus", name, "disable"]) &&
                                                            formRef.getFieldValue(["productSkus", name, "oldChecked"]) &&
                                                            formRef.getFieldValue(["productSkus", name, "oldSku"]))
                                                    }
                                                    onChange={(e) => {
                                                        const newListSkus = formRef.getFieldValue("productSkus")
                                                        newListSkus[name] = {
                                                            ...newListSkus[name],
                                                            skuCode: e.target.value
                                                                ? e.target.value?.trim()?.replace(/[\s’/`~!#*$@%+=,^&(){}[\]|;:"'<>?\\]/g, "")
                                                                : "",
                                                        }
                                                        formRef.setFieldsValue({
                                                            productSkus: newListSkus,
                                                        })
                                                    }}
                                                    onBlur={(e) => {
                                                        const newListSkus = formRef.getFieldValue("productSkus")
                                                        newListSkus[name] = {
                                                            ...newListSkus[name],
                                                            skuCode: e.target.value
                                                                ? e.target.value?.trim()?.replace(/[\s’/`~!#*$@%+=,^&(){}[\]|;:"'<>?\\]/g, "")
                                                                : "",
                                                        }
                                                        formRef.setFieldsValue({
                                                            productSkus: newListSkus,
                                                        })
                                                    }}
                                                    onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            span={4}
                                            className="flex justify-start">
                                            <Form.Item
                                                {...restField}
                                                valuePropName="checked"
                                                name={[name, "status"]}>
                                                <Switch disabled={disableEdit} />
                                            </Form.Item>
                                            <Typography.Text className="mt-2 ml-2">{trans("user_list.active")}</Typography.Text>
                                        </Col>
                                    </Row>
                                ))}
                            </>
                        )}
                    </Form.List>
                </Card>
            )}
        </Card>
    )
}
