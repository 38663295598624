import { commonReducer } from "@reducer/common.reducer"
import { vendorReducer } from "@reducer/vendor.reducer"
import { subscriberReducer } from "@reducer/subscriber.reducer"
import { productReducer } from "@reducer/product.reducer"
import { vendorDetailReducer } from "./vendor-detail.reducer"
import { productCreateReducer } from "./product-create.reducer"
import { productUpdateReducer } from "./product-update.reducer"
import { paymentStatementReducer } from "./paymentStatement.reducer"
import { paymentStatementDetailReducer } from "./paymentStatement-detail.reducer"
import { dashboardReducer } from "./dashboard.reducer"

const rootReducer = {
    common: commonReducer,
    vendor: vendorReducer,
    vendorDetail: vendorDetailReducer,
    subscriber: subscriberReducer,
    product: productReducer,
    productCreate: productCreateReducer,
    productUpdate: productUpdateReducer,
    PaymentStatement: paymentStatementReducer,
    balanceDetail: paymentStatementDetailReducer,
    dashboard: dashboardReducer
}

export default rootReducer
