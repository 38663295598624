import React from "react"
import { Dashboard } from "@screen/Dashboard"
import { Login } from "@screen/Login"
import { Logout } from "@screen/Logout"
import { Product } from "@screen/Product"
import { Vendor } from "@screen/Vendor"
import { Authentication } from "@screen/Authentication"
import { AuthenticateRoute } from "@component/AuthenticateRoute"
import { Subscriber } from "@screen/Subscriber"
import { CreateProduct } from "@screen/Product/Create"
import { EditProduct } from "@screen/Product/Edit"
import DetailVendor from "@screen/Vendor/Detail"
import { PAYMENT_STATEMENT_SCOPE, PRODUCT_SCOPE, VENDOR_SCOPE } from "./permission"
import { PaymentStatement } from '../screen/PaymentStatement';
import { AccessDenied } from "@component/AccessDenied"
import { CreatePaymentStatement } from "@screen/PaymentStatement/Create"
import { EditPaymentStatement } from "@screen/PaymentStatement/Edit"

export interface IRouteConfig {
    name: string
    path: string
    component: JSX.Element
}

export const routes: Array<IRouteConfig> = [
    {
        name: "dashboard",
        path: "/",
        component: (
            <AuthenticateRoute>
                <Dashboard />
            </AuthenticateRoute>
        ),
    },
    {
        name: "subscriber",
        path: "/subscriber",
        component: (
            <AuthenticateRoute>
                <Subscriber />
            </AuthenticateRoute>
        ),
    },
    {
        name: "vendor",
        path: "/vendor",
        component: (
            <AuthenticateRoute permissions={[VENDOR_SCOPE.VENDOR_VIEW]}>
                <Vendor />
            </AuthenticateRoute>
        ),
    },
    {
        name: "vendor",
        path: "/vendor/:id",
        component: (
            <AuthenticateRoute permissions={[VENDOR_SCOPE.VENDOR_VIEW_DETAIL]}>
                <DetailVendor />
            </AuthenticateRoute>
        ),
    },
    {
        name: "login",
        path: "/login",
        component: <Login />,
    },
    {
        name: "product",
        path: "/product",
        component: (
            <AuthenticateRoute>
                <Product />
            </AuthenticateRoute>
        ),
    },
    {
        name: "product",
        path: "/product/create",
        component: (
            <AuthenticateRoute permissions={[PRODUCT_SCOPE.PRODUCT_CREATE]}>
                <CreateProduct />
            </AuthenticateRoute>
        ),
    },
    {
        name: "product",
        path: "/product/:id",
        component: (
            <AuthenticateRoute permissions={[PRODUCT_SCOPE.PRODUCT_DETAIL_ALL, PRODUCT_SCOPE.PRODUCT_DETAIL_VIEW]}>
                <EditProduct />
            </AuthenticateRoute>
        ),
    },
    {
        name: "payment_statement",
        path: "/payment-statement",
        component: (
            <AuthenticateRoute permissions={[PAYMENT_STATEMENT_SCOPE.PAYMENT_STATEMENT_LIST_VIEW]}>
                <PaymentStatement />
            </AuthenticateRoute>
        ),
    },
    {
        name: "payment_statement",
        path: "/payment-statement/create",
        component: (
            <AuthenticateRoute permissions={[PAYMENT_STATEMENT_SCOPE.PAYMENT_STATEMENT_CREATE]}>
                <CreatePaymentStatement />
            </AuthenticateRoute>
        ),
    },
    {
        name: "payment_statement",
        path: "/payment-statement/:id",
        component: (
            <AuthenticateRoute permissions={[PAYMENT_STATEMENT_SCOPE.PAYMENT_STATEMENT_DETAIL]}>
                <EditPaymentStatement />
            </AuthenticateRoute>
        ),
    },
    {
        name: "logout",
        path: "/logout",
        component: <Logout />,
    },
    {
        name: "authentication",
        path: "/authentication",
        component: <Authentication />,
    },
    {
        name: "403",
        path: "/403",
        component: <AccessDenied />,
    },
]
