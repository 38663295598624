import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Col, Row } from "antd"
import React, { useMemo } from "react"
import { SmallDashboard } from "../SmallDashboard"
export const OrderDashboard: React.FC = () => {
    const yOrderDashboard = useAppSelector((state) => state.dashboard.yOrderDashboard)
    const tOrderDashboard = useAppSelector((state) => state.dashboard.tOrderDashboard)
    const loadingOrder = useAppSelector((state) => state.dashboard.loadingOrder)
    const listOrders = useMemo(
        () => [
            {
                title: trans("dashboard.new_order"),
                count: tOrderDashboard?.newOrders || 0,
                countExtra: yOrderDashboard?.newOrders || 0,
                icon: "fa-file",
                background: "bg-[#e0f4fc]",
            },
            {
                title: trans("order_status.PICKED_UP"),
                count: tOrderDashboard?.pickupOrders || 0,
                countExtra: yOrderDashboard?.pickupOrders || 0,
                icon: "fa-file",
                background: "bg-[#e0f4fc]",
            },
            {
                title: trans("order_status.DRAFT"),
                count: tOrderDashboard?.draftOrders || 0,
                countExtra: yOrderDashboard?.draftOrders || 0,
                icon: "fa-file",
                background: "bg-[#e0f4fc]",
            },
            {
                title: trans("order_status.CANCELLED"),
                count: tOrderDashboard?.cancelledOrders || 0,
                countExtra: yOrderDashboard?.cancelledOrders || 0,
                icon: "fa-file",
                background: "bg-[#e0f4fc]",
            },
            {
                title: trans("dashboard.new_delivered_order"),
                count: tOrderDashboard?.deliveredOrders || 0,
                countExtra: yOrderDashboard?.deliveredOrders || 0,
                icon: "fa-file",
                background: "bg-[#e0f4fc]",
            },
            {
                title: trans("dashboard.new_scheduled_order"),
                count: tOrderDashboard?.scheduledOrders || 0,
                countExtra: yOrderDashboard?.scheduledOrders || 0,
                icon: "fa-file",
                background: "bg-[#e0f4fc]",
            },
        ],
        [yOrderDashboard,tOrderDashboard]
    )
    return (
        <div className="space-layout">
            <p className="text-xl m-0">{trans("dashboard.order")}</p>
            <Row gutter={[12, 12]}>
                {listOrders?.map((item, index) => (
                    <Col
                        key={index}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={8}>
                        <SmallDashboard
                            {...item}
                            loading={loadingOrder}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    )
}
