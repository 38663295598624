import { ISubscriber, ISubscriberListResponse, ISubscriberQuery, ISubscriberRequest } from "@domain/Subscriber";
import apiClient from "@util/ApiClient";
export default class SubscriberApi {
    static getSubscribers(params: ISubscriberQuery): Promise<ISubscriberListResponse> {
        return apiClient.get(`subscribers`, { params })
    }

    static updateStatusSubscribers(id: string, body: ISubscriberRequest): Promise<Partial<ISubscriber>> {
        return apiClient.patch(`/subscribers/${id}/status`, body)
    }
}
