import { PAGINATION } from "@config/constant"
import { PRODUCT_SCOPE } from "@config/permission"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Button, Card, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { formatDateFull } from "@util/Common"
import { find, isEmpty } from "lodash"
import SecurityService from "@util/SecurityService"
import IProduct from "@domain/Product"

export const ProductTable: React.FC = () => {
    const size: number = useAppSelector((state) => state.product.pagination.size)
    const currentPage: number = useAppSelector((state) => state.product.pagination.currentPage)
    const total: number = useAppSelector((state) => state.product.pagination.total)
    const totalPage: number = useAppSelector((state) => state.product.pagination.totalPage)
    const listProduct = useAppSelector((state) => state.product.listProduct)
    const loadingList = useAppSelector((state) => state.product.loadingListProduct)
    const listGroupProduct = useAppSelector((state) => state.product.listGroupProduct)
    const userLogin = SecurityService.getUser()
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    const columns: ColumnsType<any> = [
        {
            title: trans("product_list.code"),
            className: "bold-400",
            dataIndex: "code",
            width: 120,
            fixed: "left",
            render: (code: string, record: IProduct) =>
                (userLogin?.sub === record?.createdBy && SecurityService.can(PRODUCT_SCOPE.PRODUCT_DETAIL_VIEW)) ||
                SecurityService.can(PRODUCT_SCOPE.PRODUCT_DETAIL_ALL) ? (
                    <Link to={`/product/${code}`}>{code}</Link>
                ) : (
                    code
                ),
        },
        {
            title: trans("product_list.name"),
            className: "bold-400",
            dataIndex: "name",
            width: 150,
        },
        {
            title: trans("products.manufactory"),
            className: "bold-400",
            dataIndex: "manufactory",
            width: 120,
        },
        {
            title: trans("products.group"),
            className: "bold-400",
            dataIndex: "productGroupCode",
            ellipsis: true,
            render: (productGroupCode: string) =>
                !isEmpty(find(listGroupProduct, { code: productGroupCode })) ? (
                    <Tooltip title={find(listGroupProduct, { code: productGroupCode })?.name}>
                        {find(listGroupProduct, { code: productGroupCode })?.name}
                    </Tooltip>
                ) : (
                    ""
                ),
            width: 150,
        },
        {
            title: trans("products.status"),
            className: "bold-400",
            dataIndex: "isActive",
            render: (status) => (status ? "Active" : "Inactive"),
            width: 100,
        },
        {
            title: trans("products.created_by"),
            className: "bold-400",
            dataIndex: "createdBy",
            width: 100,
        },
        {
            title: trans("products.created_at"),
            className: "bold-400",
            dataIndex: "createdAt",
            render: (date) => formatDateFull(date || ""),
            width: 120,
        },
    ]

    const handleChangePage = (page: number, pageSize: number) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        const param: any = {
            ...oldSearch,
            page: !searchParams.has("size") || Number(oldSearch?.size) === pageSize ? page : PAGINATION.DEFAULT_CURRENT_PAGE,
            size: pageSize,
        }
        setSearchParams(param)
    }
    return (
        <Card
            className="space-layout"
            title={total !== 0 ? `${trans("contact.page")} ${currentPage}/${totalPage} (${total})` : ""}
            extra={
                SecurityService.can(PRODUCT_SCOPE.PRODUCT_CREATE) && (
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="filter-btn"
                        onClick={() => navigate("/product/create")}>
                        {trans("stock_receipt.add_product")}
                    </Button>
                )
            }>
            <Table
                rowKey={"id"}
                columns={columns}
                scroll={{
                    x: true,
                }}
                loading={loadingList}
                dataSource={listProduct}
                className="bd-radius-5"
                locale={{
                    emptyText: trans("table.empty"),
                }}
                pagination={{
                    pageSize: size,
                    total: total,
                    current: currentPage,
                    pageSizeOptions: PAGINATION.DEFAULT_PAGE_SIZE_OPTIONS,
                    showSizeChanger: true,
                    onChange: handleChangePage,
                    locale: {
                        items_per_page: `/ ${trans("contact.page_change")}`,
                    },
                }}
            />
        </Card>
    )
}
