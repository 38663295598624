import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { PAGINATION, SUBSCRIBER_BREADCRUMB } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { changePageTitleAction } from "@reducer/common.reducer"
import { fetchSubscribers } from "@reducer/subscriber.reducer"
import { trans } from "@resources/localization"
import { message } from "antd"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { FilterSubscriber } from "./Fitler"
import { SubscriberTable } from "./Table"

export const Subscriber: React.FC = () => {
    const [searchParams] = useSearchParams()
    const isUpdateStatusSuccess = useAppSelector((state) => state.subscriber.isUpdateStatusSuccess)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(changePageTitleAction(trans("sidebar.subscriber")))
    }, [dispatch])

    useEffect(() => {
        dispatch(
            fetchSubscribers({
                ...Object.fromEntries(searchParams.entries()),
                size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
            })
        )
    }, [dispatch, searchParams])

    useEffect(() => {
        if (isUpdateStatusSuccess) {
            dispatch(
                fetchSubscribers({
                    ...Object.fromEntries(searchParams.entries()),
                    size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                })
            )
            message.success(trans("message.success"), 1)
        }
    }, [isUpdateStatusSuccess])

    return (
        <DefaultLayout loading={false}>
                <HeaderAntBreadCrumb breadCrumbs={SUBSCRIBER_BREADCRUMB} />
                <FilterSubscriber />
                <SubscriberTable />
        </DefaultLayout>
    )
}
