import { Button, Card, Col, Row, Select, Space } from "antd"
import React, { useEffect } from "react"
import { Form } from "antd"
import { trans } from "@resources/localization"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callGetCountry, callVendorsSuggest } from "@reducer/vendor.reducer"
import { useAppSelector } from "@hook/useAppSelector"
import { useSearchParams } from "react-router-dom"
import { forEach, isEmpty, omitBy } from "lodash"
export const Filter: React.FC = () => {
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const countries = useAppSelector((state) => state.vendor.listCountry)
    const vendors = useAppSelector((state) => state.vendor.vendorsSuggest)
    const [searchParams, setSearchParams] = useSearchParams()
    useEffect(() => {
        dispatch(callGetCountry())
        dispatch(callVendorsSuggest({}))
    }, [dispatch])
    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const search = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...search,
                ...(searchParams.has("vendors") && {
                    vendors: search?.vendors?.split(","),
                }),
            })
        } else {
            formRef.resetFields()
        }
    }, [searchParams])
    const onFilter = (values: any) => {
        setSearchParams(() => {
            const oldSearch = Object.fromEntries(searchParams.entries())
            let params: any = {
                ...oldSearch,
                ...values,
                vendors: values?.vendors ? values?.vendors?.join(",") : undefined,
            }
            delete params.createdAt
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }
    return (
        <Card className="space-layout">
            <Form
                form={formRef}
                onFinish={onFilter}
                layout="vertical">
                <Row gutter={12}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item name="country">
                            <Select
                                allowClear
                                placeholder={trans("order_list.country")}
                                onChange={(value) => {
                                    formRef.setFieldsValue({
                                        vendors: [],
                                    })
                                    dispatch(callVendorsSuggest({ countryCode: value }))
                                }}
                                filterOption={(input: any, option: any) => {
                                    return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                }}>
                                {countries?.map((item) => (
                                    <Select.Option
                                        value={item?.code}
                                        key={item?.code}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item name="vendors">
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder={trans("dashboard.select_vendor")}>
                                {vendors?.map((item) => (
                                    <Select.Option
                                        key={item?.code}
                                        value={item?.code}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit">
                                {trans("button.filter")}
                            </Button>
                            <Button
                                onClick={() => {
                                    formRef.resetFields()
                                    setSearchParams({})
                                }}
                                type="default">
                                {trans("button.clear_all_filter")}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
