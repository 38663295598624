import SubscriberApi from "@api/Subscriber";
import { PAGINATION } from "@config/constant";
import { ISubscriber, ISubscriberQuery } from "@domain/Subscriber";
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { ISubscriberRequest } from './../domain/Subscriber';

interface State {
    pagination: {
        size: number
        total: number
        current: number
        totalPage: number
    }
    loadingList: boolean
    listSubscribers: ISubscriber[]
    isUpdateStatusSuccess: boolean
}

const initState: State = {
    pagination: {
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        current: PAGINATION.DEFAULT_CURRENT_PAGE,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE
    },
    loadingList: false,
    listSubscribers: [],
    isUpdateStatusSuccess: false,
}

export const fetchSubscribers = createAsyncThunk("SUBSCRIBER.FETCH_LIST", async (subscriberQuery: ISubscriberQuery, thunkApi) => {
    try {
        return await SubscriberApi.getSubscribers(subscriberQuery)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const updateStatusSubscribers = createAsyncThunk("SUBSCRIBER.UPDATE_STATUS", async (payload: { id: string; body: ISubscriberRequest }, thunkApi) => {
    try {
        return await SubscriberApi.updateStatusSubscribers(payload.id, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const subscriberReducer = createReducer(initState, (builder) => {
    builder
        .addCase(fetchSubscribers.pending, (state) => {
            state.loadingList = true
            state.listSubscribers = []
        })
        .addCase(fetchSubscribers.fulfilled, (state, action) => {
            state.listSubscribers = action.payload.data ? action.payload.data : []
            state.pagination.current = action.payload.headers["x-page-number"]
                ? Number(action.payload.headers["x-page-number"])
                : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = action.payload.headers["x-page-size"] ? Number(action.payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = action.payload.headers["x-total-count"] ? Number(action.payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = action.payload.headers["x-page-count"] ? Number(action.payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
            state.isUpdateStatusSuccess = false
        })
        .addCase(fetchSubscribers.rejected, (state) => {
            state.loadingList = false
        })

    builder
        .addCase(updateStatusSubscribers.pending, (state) => {
            state.loadingList = true
            state.isUpdateStatusSuccess = false
        })
        .addCase(updateStatusSubscribers.fulfilled, (state) => {
            state.loadingList = false
            state.isUpdateStatusSuccess = true
        })
        .addCase(updateStatusSubscribers.rejected, (state) => {
            state.loadingList = false
            state.isUpdateStatusSuccess = false
        })
})

