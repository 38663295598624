import { IChildren, ITypeFee } from "@domain/PaymentStatement"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Card, Col, Form, InputNumber, Row, Typography } from "antd"
import { filter, find, isEmpty, sumBy } from "lodash"
import React, { useEffect } from "react"

type Props = {
    formRef: any
}

export const ListTypeFees: React.FC<Props> = ({ formRef }) => {
    const typeFees = useAppSelector((state) => state.PaymentStatement.typeFees)
    const currenciesSuggest = useAppSelector((state) => state.PaymentStatement.currenciesSuggest)

    useEffect(() => {
        if (!isEmpty(typeFees)) {
            const newList: any[] = []
            typeFees?.forEach((item: ITypeFee, index: number) => {
                newList.push({
                    ...item,
                    stt: index + 1,
                })
                if (!isEmpty(item?.children)) {
                    item?.children?.forEach((iChild: IChildren, iChildIndex: number) => {
                        newList.push({
                            ...iChild,
                            stt: `${index + 1}.${iChildIndex + 1}`,
                        })
                    })
                }
            })
            console.log("newList", newList)
            formRef.setFieldsValue({
                paymentStatementItems: newList?.map((item: any) => ({
                    stt: item?.stt || "",
                    code: item?.code || "",
                    id: item?.id || "",
                    children: item?.children || [],
                    parent: item?.parent || "",
                    type: item?.type || "",
                    formula: item?.formula || "",
                    amount: 0,
                    vietnamAmount: 0,
                    totalParentOriginal: 0,
                    totalParentVND: 0,
                    vendorOriginal: 0,
                    vendorVND: 0,
                    order: item?.order,
                })),
            })
        }
    }, [typeFees])
    return (
        <Card className="space-layout" title={trans("payment_statement.finance")}>
            <Row
                gutter={8}
                className="border border-b-0">
                <Col
                    span={2}
                    className="border-r py-2 flex items-center justify-center font-medium">
                    {trans("table.number")}
                </Col>
                <Col
                    span={10}
                    className="border-r py-2 flex items-center justify-center font-medium">
                    {trans("payment_statement.type")}
                </Col>
                <Col
                    span={6}
                    className="border-r py-2 flex items-center justify-center font-medium">
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.currencyCode !== next.currencyCode}>
                        {({ getFieldValue }) => (
                            <Typography.Text>
                                {trans("payment_statement.original")}{" "}
                                {getFieldValue("currencyCode") && <span>({find(currenciesSuggest, { code: getFieldValue("currencyCode") })?.symbol})</span>}
                            </Typography.Text>
                        )}
                    </Form.Item>
                </Col>
                <Col
                    span={6}
                    className="border-r py-2 flex items-center justify-center font-medium">
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.conversionCurrencyCode !== next.conversionCurrencyCode}>
                        {({ getFieldValue }) => (
                            <Typography.Text>
                                {trans("payment_statement.amount")}{" "}
                                {getFieldValue("conversionCurrencyCode") && (
                                    <span>({find(currenciesSuggest, { code: getFieldValue("conversionCurrencyCode") })?.symbol})</span>
                                )}
                            </Typography.Text>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Form.List name="paymentStatementItems">
                {(fields) => (
                    <>
                        {fields.map(({ name, ...restField }) => (
                            <Row
                                className={`border ${name < fields?.length - 1 ? "border-b-0" : ""}`}
                                gutter={8}
                                key={name}>
                                <Col
                                    span={2}
                                    className="border-r flex items-center justify-center py-2">
                                    {formRef.getFieldValue(["paymentStatementItems", name, "stt"])}
                                </Col>
                                <Col
                                    span={10}
                                    className="border-r flex items-center justify-start py-2">
                                    {trans(`type_fee.${formRef.getFieldValue(["paymentStatementItems", name, "code"])}`)}
                                </Col>
                                <Col
                                    span={6}
                                    className="border-r flex items-center justify-center py-2">
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(pre, next) => pre.currencyCode !== next.currencyCode}>
                                        {({ getFieldValue }) => (
                                            <Form.Item
                                                {...restField}
                                                name={
                                                    formRef.getFieldValue(["paymentStatementItems", name, "code"]) === "vendor_revenue"
                                                        ? [name, "vendorOriginal"]
                                                        : !isEmpty(formRef.getFieldValue(["paymentStatementItems", name, "children"]))
                                                        ? [name, "totalParentOriginal"]
                                                        : [name, "amount"]
                                                }
                                                className="custom--form">
                                                <InputNumber
                                                    min={0}
                                                    disabled={
                                                        !isEmpty(formRef.getFieldValue(["paymentStatementItems", name, "children"])) ||
                                                        formRef.getFieldValue(["paymentStatementItems", name, "code"]) === "vendor_revenue" ||
                                                        !getFieldValue("currencyCode")
                                                    }
                                                    className="w-full"
                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    parser={(value: any) =>
                                                        value
                                                            ? getFieldValue("currencyCode") !== "VND"
                                                                ? parseFloat(value?.toString()?.replace(/[-&/\\#,+()$~%'":*?<>{}]/g, "")).toFixed(2)
                                                                : value?.toString()?.replace(/[&/\\#,+()$~%'":.*?<>{}]/g, "")
                                                            : 0
                                                    }
                                                    onChange={() => {
                                                        const listOriginal = formRef.getFieldValue("paymentStatementItems")
                                                        const maxFee = find(listOriginal, { order: 1 })?.amount || 0
                                                        const executeFees = filter(
                                                            listOriginal,
                                                            (iFil: any) => iFil?.code !== "vendor_revenue" && iFil?.order !== 1 && isEmpty(iFil?.children)
                                                        )
                                                        const totalExecuteFees = !isEmpty(executeFees) ? sumBy(executeFees, "amount") : 0
                                                        const newList = listOriginal?.map((iMap: any) => ({
                                                            ...iMap,
                                                            totalParentOriginal: parseFloat(
                                                                sumBy(
                                                                    filter(listOriginal, (iSmallFil: any) => iSmallFil?.parent === iMap?.code),
                                                                    "amount"
                                                                )?.toString()
                                                            ).toFixed(2),
                                                            vendorOriginal: parseFloat((maxFee - totalExecuteFees)?.toString()).toFixed(2),
                                                        }))
                                                        formRef.setFieldsValue({
                                                            paymentStatementItems: newList,
                                                        })
                                                    }}
                                                />
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col
                                    span={6}
                                    className="border-r flex items-center justify-center py-2">
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(pre, next) => pre.conversionCurrencyCode !== next.conversionCurrencyCode}>
                                        {({ getFieldValue }) => (
                                            <Form.Item
                                                {...restField}
                                                name={
                                                    formRef.getFieldValue(["paymentStatementItems", name, "code"]) === "vendor_revenue"
                                                        ? [name, "vendorVND"]
                                                        : !isEmpty(formRef.getFieldValue(["paymentStatementItems", name, "children"]))
                                                        ? [name, "totalParentVND"]
                                                        : [name, "vietnamAmount"]
                                                }
                                                className="custom--form">
                                                <InputNumber
                                                    min={0}
                                                    className="w-full"
                                                    disabled={
                                                        !getFieldValue("conversionCurrencyCode") ||
                                                        !isEmpty(formRef.getFieldValue(["paymentStatementItems", name, "children"])) ||
                                                        formRef.getFieldValue(["paymentStatementItems", name, "code"]) === "vendor_revenue"
                                                    }
                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    parser={(value: any) => (value ? value?.toString()?.replace(/[&/\\#,+()$~%'":.*?<>{}]/g, "") : 0)}
                                                    onChange={() => {
                                                        const listVND = formRef.getFieldValue("paymentStatementItems")
                                                        const maxFee = find(listVND, { order: 1 })?.vietnamAmount || 0
                                                        const executeFees = filter(
                                                            listVND,
                                                            (iFil: any) => iFil?.code !== "vendor_revenue" && iFil?.order !== 1 && isEmpty(iFil?.children)
                                                        )
                                                        const totalExecuteFees = !isEmpty(executeFees) ? sumBy(executeFees, "vietnamAmount") : 0
                                                        const newList = listVND?.map((iMap: any) => ({
                                                            ...iMap,
                                                            totalParentVND: sumBy(
                                                                filter(listVND, (iSmallFil: any) => iSmallFil?.parent === iMap?.code),
                                                                "vietnamAmount"
                                                            ),
                                                            vendorVND: maxFee - totalExecuteFees,
                                                        }))
                                                        formRef.setFieldsValue({
                                                            paymentStatementItems: newList,
                                                        })
                                                    }}
                                                />
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}
                    </>
                )}
            </Form.List>
        </Card>
    )
}
