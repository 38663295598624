import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Col, Form, Row, Input, Select, Space, Button, Card } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { forEach, isEmpty, omitBy } from "lodash"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
export const Filter: React.FC = () => {
    const [formRef] = Form.useForm()
    const listCountry = useAppSelector((state) => state.vendor.listCountry)
    const loadingList = useAppSelector((state) => state.vendor.loadingListVendor)
    const [searchParams, setSearchParams] = useSearchParams()
    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const search = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...search,
                ...(searchParams.has("countryCode") && {
                    countryCode: search.countryCode.split(","),
                }),
            })
        } else {
            formRef.resetFields()
        }
    }, [searchParams])
    const handleSubmit = (values: { codeOrName: string; countryCode: string[] }) => {
        setSearchParams(() => {
            let params: any = {
                ...Object.fromEntries(searchParams.entries()),
                ...values,
                countryCode: values.countryCode ? values.countryCode.join(",") : undefined,
            }
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }
    return (
        <Card className="space-layout">
            <Form
                form={formRef}
                onFinish={handleSubmit}
                layout="vertical">
                <Row gutter={12}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("products.name_or_code")}
                            name="codeOrName">
                            <Input
                                allowClear
                                placeholder={trans("vendor.name_code_filter")}
                                suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            name="countryCode"
                            label={trans("vendor.country")}>
                            <Select
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => {
                                    return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                }}
                                placeholder={trans("vendor.select_country")}
                                mode="multiple">
                                {listCountry?.map((item) => (
                                    <Select.Option
                                        key={item.id}
                                        value={item.code}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loadingList}>
                                {trans("button.filter")}
                            </Button>
                            <Button
                                type="default"
                                onClick={() => {
                                    formRef.resetFields()
                                    setSearchParams({})
                                }}
                                loading={loadingList}>
                                {trans("button.clear_all_filter")}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
