import React, { useEffect } from "react"
import DefaultLayout from "@component/Layout/Default"
import { trans } from "@resources/localization"
import { changePageTitleAction } from "@reducer/common.reducer"
import { Filter } from "./Filter"
import { ContactDashboard } from "./Contact"
import { useSearchParams } from "react-router-dom"
import { callGetContactTodayDashboard, callGetContactYesterdayDashboard, callGetTodayOrderDashboard, callGetYesterdayOrderDashboard } from "@reducer/dashboard.reducer"
import { useAppDispatch } from "@hook/useAppDispatch"
import moment from "moment-timezone"
import SecurityService from "@util/SecurityService"
import { DASHBOARD_SCOPE } from "@config/permission"
import { OrderDashboard } from "./Order"
import { useAppSelector } from "@hook/useAppSelector"

export const Dashboard = (props: any) => {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    const userInfo = useAppSelector((state) => state.common.userInfo)

    useEffect(() => {
        dispatch(changePageTitleAction(trans("sidebar.dashboard")))
    }, [dispatch])
    
    useEffect(() => {
        const paramsOld = Object.fromEntries(searchParams.entries())
       if(userInfo.zoneinfo){
        const timezone: string = userInfo.zoneinfo;
        const nowWithTimeZone = moment().tz(timezone);
        const yesterdayWithTimeZone = moment().tz(timezone).subtract(1,'day');
        if(SecurityService.can(DASHBOARD_SCOPE.DASHBOARD_ORDER)){
            dispatch(
                callGetTodayOrderDashboard({
                    ...paramsOld,
                    vendors: searchParams?.has("vendors") ? paramsOld?.vendors?.split(",") : undefined,
                    beginDate: nowWithTimeZone.startOf('day').toISOString(),
                    endDate: nowWithTimeZone.endOf('day').toISOString(),
                    timezone
                })
            )
            dispatch(
                callGetYesterdayOrderDashboard({
                    ...paramsOld,
                    vendors: searchParams?.has("vendors") ? paramsOld?.vendors?.split(",") : undefined,
                    beginDate: yesterdayWithTimeZone.startOf('day').toISOString(),
                    endDate: yesterdayWithTimeZone.endOf('day').toISOString(),
                    timezone
                })
            )
        }
        if(SecurityService.can(DASHBOARD_SCOPE.DASHBOARD_CONTACT)){
            dispatch(
                callGetContactTodayDashboard({
                    ...paramsOld,
                    vendors: searchParams?.has("vendors") ? paramsOld?.vendors?.split(",") : undefined,
                    beginDate: nowWithTimeZone.startOf('day').toISOString(),
                    endDate: nowWithTimeZone.endOf('day').toISOString(),
                    timezone
                })
            )
            dispatch(
                callGetContactYesterdayDashboard({
                    ...paramsOld,
                    vendors: searchParams?.has("vendors") ? paramsOld?.vendors?.split(",") : undefined,
                    beginDate: yesterdayWithTimeZone.startOf('day').toISOString(),
                    endDate: yesterdayWithTimeZone.endOf('day').toISOString(),
                    timezone
                })
            )
        }
       }
    }, [dispatch, searchParams, userInfo])

    return (
        <DefaultLayout
            {...props}
            loading={false}
            title={trans("sidebar.dashboard")}>
            <Filter />
            {SecurityService.can(DASHBOARD_SCOPE.DASHBOARD_ORDER) && <OrderDashboard />}
            {SecurityService.can(DASHBOARD_SCOPE.DASHBOARD_CONTACT) && <ContactDashboard />}
        </DefaultLayout>
    )
}
