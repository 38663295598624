import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
import CommonApi from "@api/Common"
import { ITimezone, IUserInfo } from "@domain/Common"

interface State {
    pageTitle: string
    timezones: ITimezone[]
    userInfo: IUserInfo
    loadingUpdate: boolean
}

const initState: State = {
    pageTitle: "Common",
    timezones: [],
    userInfo: {},
    loadingUpdate: false
}

export const changePageTitleAction = createAction<string>("CHANGE_PAGE_TITLE")

export const callGetTimezones = createAsyncThunk("COMMON.GET_TIMEZONE", async (_, thunkApi) => {
    try {
        return await CommonApi.getTimezones()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetAuthUserInfo = createAsyncThunk("COMMON.GET_USER", async (_, thunkApi) => {
    try {
        return await CommonApi.getAuthUserInfo()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callUpdateUserInfo = createAsyncThunk("COMMON.UPDATE_USER", async (body: {zoneInfo: string}, thunkApi) => {
    try {
        return await CommonApi.updateUserInfo(body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const commonReducer = createReducer(initState, (builder) => {
    builder.addCase(changePageTitleAction, (state, action: PayloadAction<string>) => {
        state.pageTitle = action.payload
    })
    builder
        .addCase(callGetTimezones.pending, (state) => {
            state.timezones = []
        })
        .addCase(callGetTimezones.fulfilled, (state, { payload }) => {
            state.timezones = payload?.data
        })
        .addCase(callGetTimezones.rejected, (state) => {
            state.timezones = []
        })
    builder
        .addCase(callGetAuthUserInfo.pending, (state) => {
            state.userInfo = {}
        })
        .addCase(callGetAuthUserInfo.fulfilled, (state, { payload }) => {
            localStorage.setItem('timezone', payload?.data?.zoneinfo || 'Asia/Ho_Chi_Minh')
            state.userInfo = payload?.data
        })
        .addCase(callGetAuthUserInfo.rejected, (state) => {
            state.userInfo = {}
        })
    builder
        .addCase(callUpdateUserInfo.pending, (state) => {
            state.loadingUpdate = true
        })
        .addCase(callUpdateUserInfo.fulfilled, (state) => {
            state.loadingUpdate = false
        })
        .addCase(callUpdateUserInfo.rejected, (state) => {
            state.loadingUpdate = false
        })
})
