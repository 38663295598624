import VendorApi from "@api/VendorApi"
import { PAGINATION } from "@config/constant"
import { IUserOfVendor, IUserQuery, IVendorDetailRequest } from "@domain/Vendor"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    detailVendor: IVendorDetailRequest
    loadingVendor: boolean
    listUsers: Array<IUserOfVendor>
    paginationUsers: {
        currentPage: number
        size: number
        total: number
        totalPage: number
    }
    loadingListUser: boolean
}

const initState: State = {
    detailVendor: {
        id: "",
        name: "",
        code: "",
        manager: "",
        vendorMapping: [],
        country: { id: "", name: "" },
        countryCode: "",
        createdBy: "",
        createdAt: "",
    },
    loadingVendor: false,
    listUsers: [],
    paginationUsers: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    loadingListUser: false,
}

export const callGetDetailVendor = createAsyncThunk("VENDOR.GET_DETAIL_VENDOR", async (code: string, thunkApi) => {
    try {
        return await VendorApi.getDetailVendor(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetListUserOfVendor = createAsyncThunk("VENDOR.GET_LIST_USERS_OF_VENDOR", async (payload: { code: string; params: IUserQuery }, thunkApi) => {
    try {
        return await VendorApi.getListUserOfVendor(payload.code, payload.params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const vendorDetailReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetDetailVendor.pending, (state) => {
            state.loadingVendor = true
        })
        .addCase(callGetDetailVendor.fulfilled, (state, action) => {
            state.detailVendor = action.payload.data || {}
            state.loadingVendor = false
        })
        .addCase(callGetDetailVendor.rejected, (state) => {
            state.loadingVendor = false
        })

    builder
        .addCase(callGetListUserOfVendor.pending, (state) => {
            state.loadingListUser = true
        })
        .addCase(callGetListUserOfVendor.fulfilled, (state, { payload }) => {
            state.listUsers = payload.data || []
            state.paginationUsers.currentPage = Number(payload.headers["x-page-number"]) || PAGINATION.DEFAULT_CURRENT_PAGE
            state.paginationUsers.size = Number(payload.headers["x-page-size"]) || PAGINATION.DEFAULT_PAGE_SIZE
            state.paginationUsers.total = Number(payload.headers["x-total-count"]) || PAGINATION.DEFAULT_TOTAL_ITEM
            state.paginationUsers.totalPage = Number(payload.headers["x-page-count"]) || PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingListUser = false
        })
        .addCase(callGetListUserOfVendor.rejected, (state) => {
            state.loadingListUser = false
        })
})
