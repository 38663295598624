import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
import { PAGINATION } from "@config/constant"
import IVendor, { ICountry, IVendorQuery, IVendorRequest, IVendorSuggest, IVendorSuggestQuery } from "@domain/Vendor"
import VendorApi from "@api/VendorApi"

interface State {
    pagination: {
        currentPage: number
        size: number
        total: number
        totalPage: number
    }
    isShowAddVendor: boolean
    listCountry: ICountry[]
    loadingCreate: boolean
    listVendor: IVendor[]
    loadingListVendor: boolean
    vendorsSuggest: IVendorSuggest[]
}

const initState: State = {
    pagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    isShowAddVendor: false,
    listCountry: [],
    loadingCreate: false,
    listVendor: [],
    loadingListVendor: false,
    vendorsSuggest: []
}
export const changeAddVendorVisible = createAction<boolean>("VENDOR.CHANGE_ADD_VENDOR_VISIBLE")

export const callGetCountry = createAsyncThunk("COUNTRY.GET_LIST", async (_, thunkApi) => {
    try {
        return await VendorApi.getListCountry()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callCreateVendor = createAsyncThunk("VENDOR.CREATE_VENDOR", async (payload: IVendorRequest, thunkApi) => {
    try {
        return await VendorApi.addVendor(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callListVendor = createAsyncThunk("VENDOR.GET_LIST", async (params: IVendorQuery, thunkApi) => {
    try {
        return await VendorApi.getVendors(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callVendorsSuggest = createAsyncThunk("VENDOR.GET_VENDOR_SUGGEST", async (params: IVendorSuggestQuery, thunkApi) => {
    try {
        return await VendorApi.getVendorAutocomplete(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const vendorReducer = createReducer(initState, (builder) => {
    builder.addCase(changeAddVendorVisible, (state, action: PayloadAction<boolean>) => {
        state.isShowAddVendor = action.payload
    })

    builder
        .addCase(callGetCountry.pending, (state) => {
            state.listCountry = []
        })
        .addCase(callGetCountry.fulfilled, (state, action) => {
            state.listCountry = action.payload.data
        })
        .addCase(callGetCountry.rejected, (state) => {
            state.listCountry = []
        })

    builder
        .addCase(callCreateVendor.pending, (state) => {
            state.loadingCreate = true
        })
        .addCase(callCreateVendor.fulfilled, (state) => {
            state.loadingCreate = false
        })
        .addCase(callCreateVendor.rejected, (state) => {
            state.loadingCreate = false
        })
    builder
        .addCase(callListVendor.pending, (state) => {
            state.loadingListVendor = true
            state.listVendor = []
        })
        .addCase(callListVendor.fulfilled, (state, action) => {
            state.listVendor = action.payload.data ? action.payload.data : []
            state.pagination.currentPage = action.payload.headers["x-page-number"]
                ? Number(action.payload.headers["x-page-number"])
                : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = action.payload.headers["x-page-size"] ? Number(action.payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = action.payload.headers["x-total-count"] ? Number(action.payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = action.payload.headers["x-page-count"] ? Number(action.payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingListVendor = false
        })
        .addCase(callListVendor.rejected, (state) => {
            state.loadingListVendor = false
            state.listVendor = []
        })
        builder
        .addCase(callVendorsSuggest.pending, (state) => {
            state.vendorsSuggest = []
        })
        .addCase(callVendorsSuggest.fulfilled, (state, {payload}) => {
            state.vendorsSuggest = payload?.data
        })
        .addCase(callVendorsSuggest.rejected, (state) => {
            state.vendorsSuggest = []
        })
})
