export const VENDOR_SCOPE = {
    VENDOR_CREATE: "vendor:create",
    VENDOR_ADD_USER: "vendor:user:add",
    VENDOR_SET_USER_ROLE: "vendor:user:setrole",
    VENDOR_VIEW: "vendor:view",
    VENDOR_UPDATE_ROLES: "vendor:setting:roles",
    VENDOR_VIEW_DETAIL: "vendor:detail:view",
}

export const SUBSCRIBER_SCOPE = {
    SUBSCRIBER_UPDATE_STATUS: "business:update",
}

export const PRODUCT_SCOPE = {
    PRODUCT_CREATE: "product:create",
    PRODUCT_DETAIL_ALL: "product:detail:viewall",
    PRODUCT_DETAIL_VIEW: "product:detail:view",
    PRODUCT_LIST_VIEW_ALL: "product:list:viewall",
    PRODUCT_LIST_VIEW: "product:list:view",
    PRODUCT_UPDATE: "product:update",
}
export const PAYMENT_STATEMENT_SCOPE = {
    PAYMENT_STATEMENT_LIST_VIEW: "payment_statement:list:view",
    PAYMENT_STATEMENT_CREATE: "payment_statement:create",
    PAYMENT_STATEMENT_DETAIL: "payment_statement:detail:view",
    PAYMENT_STATEMENT_UPDATE: "payment_statement:detail:update",
}
export const DASHBOARD_SCOPE = {
    DASHBOARD_CONTACT: 'dashboard:contact:view',
    DASHBOARD_ORDER: 'dashboard:order:view'
}
