import IProduct, { IProductQuery, IProductRequest } from "@domain/Product"
import IVendor, { IVendorQuery } from "@domain/Vendor"
import apiClient from "@util/ApiClient"

export default class ProductApi {
    static getListProduct(params: IProductQuery): Promise<{ data: IProduct[], headers: { [key: string]: any } }> {
        return apiClient.get(`products`, { params })
    }
    static getGroupProduct() {
        return apiClient.get("/product-groups/autocomplete")
    }
    static createProduct(payload: IProductRequest): Promise<any> {
        return apiClient.post("/products", payload)
    }
    static getVendorSuggest(params: IVendorQuery): Promise<{ data: IVendor[] }> {
        return apiClient.get(`vendors/autocomplete`, { params })
    }
    static getDetailProduct(code: string): Promise<{ data: IProduct }> {
        return apiClient.get(`products/${code}`)
    }
    static updateProduct(code:string,payload: IProductRequest): Promise<any> {
        return apiClient.patch(`/products/${code}`, payload)
    }
    static deleteVendors(code:string,payload: {vendorCodes: string[]}): Promise<any> {
        return apiClient.delete(`products/${code}/vendors`, {
            params: payload
        })
    }
}
