import { ITimezone, IUserInfo } from "@domain/Common"
import apiClient from "@util/ApiClient"

export default class CommonApi {
    static getTimezones(): Promise<{ data: ITimezone[] }> {
        return apiClient.get(`time-zones`)
    }
    static getAuthUserInfo(): Promise<{ data: IUserInfo }> {
        return apiClient.get(`auth/user/info`)
    }
    static updateUserInfo(body :{zoneInfo: string}): Promise<any> {
        return apiClient.patch(`users`, body)
    }
}
