import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

import { PaymentStatementApi } from "@api/PaymentStatementApi"
import { IDetailPaymentStatement } from "@domain/DetailPaymentStatement"
import { IPaymentStatementRequest } from "@domain/PaymentStatement"

interface State {
    dataDetail: IDetailPaymentStatement
    loadingDetail: boolean
    loadingUpdate: boolean
}

const initState: State = {
    dataDetail: {},
    loadingDetail: false,
    loadingUpdate: false
}

export const getDetailBalance = createAsyncThunk("DETAIL_BALANCE", async (code: string, thunkApi) => {
    try {
        return await PaymentStatementApi.getDetailPaymentStatement(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callUpdateBalance = createAsyncThunk("DETAIL_BALANCE.UPDATE", async (payload: { code: string, body: IPaymentStatementRequest }, thunkApi) => {
    try {
        return await PaymentStatementApi.updatePaymentStatement(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const paymentStatementDetailReducer = createReducer(initState, (builder) => {
    builder
        .addCase(getDetailBalance.pending, (state) => {
            state.loadingDetail = true
            state.dataDetail = {}
        })
        .addCase(getDetailBalance.fulfilled, (state, { payload }) => {
            state.loadingDetail = false
            state.dataDetail = payload?.data
        })
        .addCase(getDetailBalance.rejected, (state) => {
            state.loadingDetail = false
            state.dataDetail = {}
        })
    builder
        .addCase(callUpdateBalance.pending, (state) => {
            state.loadingUpdate = true
        })
        .addCase(callUpdateBalance.fulfilled, (state) => {
            state.loadingUpdate = false
        })
        .addCase(callUpdateBalance.rejected, (state) => {
            state.loadingUpdate = false
        })

})
