import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Col, Row } from "antd"
import React, { useMemo } from "react"
import { SmallDashboard } from "../SmallDashboard"
export const ContactDashboard: React.FC = () => {
    const yContactDashboard = useAppSelector((state) => state.dashboard.yContactDashboard)
    const tContactDashboard = useAppSelector((state) => state.dashboard.tContactDashboard)
    const loadingContact = useAppSelector(state => state.dashboard.loadingContact)
    const listContact = useMemo(
        () => [
            {
                title: trans("dashboard.new_contact"),
                count: tContactDashboard?.newContact || 0,  
                countExtra: yContactDashboard?.newContact || 0,
                icon: "fa-address-card",
                background: "bg-[#ebf5e1]",
            },
            {
                title: trans("dashboard.new_call"),
                count: tContactDashboard?.calledContact || 0,
                countExtra: yContactDashboard?.calledContact || 0,
                icon: "fa-address-card",
                background: "bg-[#ebf5e1]",
            },
        ],
        [yContactDashboard, tContactDashboard]
    )
    return (
        <div className="space-layout">
            <p className="text-xl m-0">{trans("dashboard.contact")}</p>
            <Row gutter={12}>
                {listContact?.map((item, index) => (
                    <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}>
                        <SmallDashboard {...item} loading={loadingContact} />
                    </Col>
                ))}
            </Row>
        </div>
    )
}
