import IVendor from "@domain/Vendor"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callDeleteVendors } from "@reducer/product.reducer"
import { trans } from "@resources/localization"
import { formatDateFull } from "@util/Common"
import { Button, Card, Modal, Popover, Space, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { AddVendor } from "./AddVendor"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { isEmpty } from "lodash"
import SecurityService from "@util/SecurityService"
import { PRODUCT_SCOPE } from "@config/permission"
import { useAppSelector } from "@hook/useAppSelector"
type Props = {
    listVendors: IVendor[]
    setListVendors: (data: any[]) => void
}
export const Vendors: React.FC<Props> = ({ listVendors, setListVendors }) => {
    const [selectedVendors, setSelectedVendors] = useState<any>([])
    const [open, setOpen] = useState<boolean>(false)
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const disableEdit = !SecurityService.can(PRODUCT_SCOPE.PRODUCT_UPDATE)
    const loadingVendor = useAppSelector(state => state.product.loadingVendor)

    const onDeletedVendor = (oldVendors: string[], newVendors: string[]) => {
        Modal.confirm({
            title: trans("message.confirm_delete"),
            icon: <ExclamationCircleOutlined />,
            okText: trans("button.delete"),
            cancelText: trans("button.cancel"),
            onOk: () => {
                if (!isEmpty(oldVendors)) {
                    dispatch(
                        callDeleteVendors({
                            code: id?.toString() || "",
                            body: { vendorCodes: oldVendors },
                        })
                    ).then((result: any) => {
                        if (result?.payload?.status === 202) {
                            const listFilter = listVendors?.filter((item) => ![...oldVendors, ...newVendors].includes(item.code))
                            setListVendors(listFilter)
                            setSelectedVendors([])
                        }
                    })
                } else {
                    const listFilter = listVendors?.filter((item) => ![...oldVendors, ...newVendors].includes(item.code))
                    setListVendors(listFilter)
                    setSelectedVendors([])
                }
            },
        })
    }
    const columns: ColumnsType<any> = [
        {
            title: trans("products.vendor_code"),
            className: "bold-400",
            dataIndex: "code",
        },
        {
            title: trans("products.vendor_name"),
            className: "bold-400",
            dataIndex: "name",
        },
        {
            title: trans("products.vendor_owner"),
            className: "bold-400",
            dataIndex: "manager",
        },
        {
            title: trans("products.vendor_created_at"),
            className: "bold-400",
            dataIndex: "createdAt",
            render: (date: string) => (date ? formatDateFull(date) : ""),
        },
        {
            title: "",
            className: "bold-400",
            dataIndex: "action",
            width: 100,
            render: (text: string, record: IVendor) =>
                !disableEdit && (
                    <Button
                        type="link"
                        onClick={() => {
                            if (record?.status === "old") {
                                onDeletedVendor([record?.code], [])
                            } else {
                                const listFilter = listVendors?.filter((item) => item.id !== record.id)
                                setListVendors(listFilter)
                            }
                        }}
                        icon={<i className="fa-solid fa-trash"></i>}
                    />
                ),
        },
    ]
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedVendors(newSelectedRowKeys)
    }
    return (
        <Card
            className="space-layout"
            extra={
                <Space>
                    {!disableEdit && (
                        <Popover
                            content={
                                open && (
                                    <AddVendor
                                        onSelectVendor={(data: IVendor) => {
                                            const listOld = listVendors.slice()
                                            setListVendors([...listOld, data])
                                        }}
                                        listVendors={listVendors}
                                        onClose={() => setOpen(false)}
                                    />
                                )
                            }
                            title=""
                            placement="left"
                            trigger="click"
                            open={open}
                            onOpenChange={() => setOpen((old) => !old)}>
                            <Button type="primary">{trans("products.add_vendor")}</Button>
                        </Popover>
                    )}
                    {!isEmpty(selectedVendors) && (
                        <Button
                            type="link"
                            icon={<i className="fa-solid fa-trash"></i>}
                            onClick={() => {
                                const listOldFilter = listVendors
                                    ?.filter((item) => selectedVendors.includes(item.id) && item?.status === "old")
                                    ?.map((iMap: any) => iMap?.code)
                                const listNewFilter = listVendors
                                    ?.filter((item) => selectedVendors.includes(item.id) && (!item?.status || item?.status !== "old"))
                                    ?.map((iMap: any) => iMap?.code)
                                onDeletedVendor(listOldFilter, listNewFilter)
                            }}
                        />
                    )}
                </Space>
            }>
            <Table
                rowKey={"id"}
                columns={columns}
                loading={loadingVendor}
                dataSource={listVendors}
                className="bd-radius-5 w-full"
                pagination={false}
                locale={{
                    emptyText: trans("table.empty"),
                }}
                rowSelection={{
                    type: "checkbox",
                    onChange: onSelectChange,
                    getCheckboxProps: () => ({
                        disabled: disableEdit,
                    }),
                }}
            />
        </Card>
    )
}
