import { PAYMENT_STATEMENT_SCOPE } from "@config/permission"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { Card, Col, DatePicker, Form, Row, Select, Typography } from "antd"
import React from "react"
export const InfoBalance: React.FC = () => {
    const vendorsSuggest = useAppSelector((state) => state.vendor.vendorsSuggest)
    const currenciesSuggest = useAppSelector((state) => state.PaymentStatement.currenciesSuggest)
    const statuses = useAppSelector((state) => state.PaymentStatement.statuses)
    return (
        <Card className="space-layout">
            <Row gutter={12}>
                <Col
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        name="vendorCode"
                        label={trans("payment_statement.vendor")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Select
                            showSearch
                            disabled
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => {
                                return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                            }}
                            filterSort={(optionA: any, optionB: any) => {
                                return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                            }}
                            placeholder={trans("payment_statement.vendor_pl")}>
                            {vendorsSuggest?.map((item) => (
                                <Select.Option
                                    key={item?.id}
                                    value={item?.code}>
                                    {item?.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        name="periodAt"
                        label={trans("payment_statement.period")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <DatePicker.RangePicker
                            disabled
                            format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                            disabledDate={(current: any) => current && current >= new Date()}
                            placeholder={[trans("contact.from"), trans("contact.to")]}
                        />
                    </Form.Item>
                </Col>
                <Col
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Typography.Text className="block mb-2">
                        <span className="text-red-500">*</span>
                        {trans("payment_statement.currency")}
                    </Typography.Text>
                    <div className="flex items-end">
                        <Form.Item
                            name="currencyCode"
                            className="w-1/2"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                            ]}>
                            <Select
                                showSearch
                                className="w-1/2"
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => {
                                    return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                }}
                                disabled
                                placeholder={trans("payment_statement.currency_pl")}>
                                {currenciesSuggest?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.code}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="conversionCurrencyCode"
                            className="w-1/2"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                            ]}>
                            <Select
                                showSearch
                                className="w-1/2"
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => {
                                    return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                }}
                                disabled
                                placeholder={trans("payment_statement.currency_pl")}>
                                {currenciesSuggest?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.code}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </Col>
                <Col
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}>
                    <Form.Item
                        name="statusCode"
                        label={trans("campaign.status")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => {
                                return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                            }}
                            filterSort={(optionA: any, optionB: any) => {
                                return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                            }}
                            disabled={!SecurityService.can(PAYMENT_STATEMENT_SCOPE.PAYMENT_STATEMENT_UPDATE)}
                            placeholder={trans("contact.select_status")}>
                            {statuses?.map((item) => (
                                <Select.Option
                                    key={item?.id}
                                    value={item?.code}>
                                    {trans(`payment_statement.${item?.code}`)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    )
}
