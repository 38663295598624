import IVendor, { ICountry, IUserQuery, IVendorDetailRequest, IVendorQuery, IVendorRequest, IVendorSuggest, IVendorSuggestQuery } from "@domain/Vendor"
import apiClient from "@util/ApiClient"

export default class VendorApi {
    static getListCountry(): Promise<{ data: ICountry[] }> {
        return apiClient.get(`countries`)
    }
    static addVendor(payload: IVendorRequest): Promise<any> {
        return apiClient.post(`vendors`, payload)
    }
    static getVendors(params: IVendorQuery): Promise<{ status: number; data: IVendor[]; headers: { [key: string]: any } }> {
        return apiClient.get(`vendors`, { params })
    }
    static getDetailVendor(code: string): Promise<{ data: IVendorDetailRequest }> {
        return apiClient.get(`vendors/${code}`)
    }
    static getListUserOfVendor(code: string, params: IUserQuery): Promise<any> {
        return apiClient.get(`vendors/${code}/users`, { params })
    }
    static getVendorAutocomplete(params: IVendorSuggestQuery): Promise<{data: IVendorSuggest[]}> {
        return apiClient.get(`vendors/autocomplete`, { params })
    }
}
