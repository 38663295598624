import { PRODUCT_SCOPE } from "@config/permission"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { Col, Row, Form, Input, Card, Typography, Select, Switch } from "antd"
import React from "react"
import ReactQuill from "react-quill"
import "@resources/less/page/product.less"

type Props = {
    formRef: any
}
export const InfoProduct: React.FC<Props> = ({ formRef }) => {
    const listProductGroup = useAppSelector((state) => state.product.listGroupProduct)
    const dataDetail = useAppSelector((state) => state.productUpdate.detailProduct)
    const disableEdit = !SecurityService.can(PRODUCT_SCOPE.PRODUCT_UPDATE)
    const toolbarOptions = [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ direction: "rtl" }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"],
    ]
    return (
        <Card className="space-layout custom-bottom" title={dataDetail?.name}>
            <Row
                gutter={12}
                className="pb-10">
                <Col xs={{span: 24}} sm={{span:12}} md={{span: 12}} lg={{span: 8}}>
                    <Form.Item
                        label={trans("product.name")}
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}
                        name="name">
                        <Input
                            disabled={disableEdit}
                            maxLength={128}
                            placeholder={trans("product.name_pl")}
                            onBlur={(e) => {
                                formRef.setFieldsValue({
                                    name: e.target.value.trim(),
                                })
                            }}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 24}} sm={{span:12}} md={{span: 12}} lg={{span: 8}}>
                    <Form.Item
                        label={trans("product.code")}
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: trans("message.required"),
                            },
                        ]}>
                        <Input
                            maxLength={16}
                            placeholder={trans("product.code_pl")}
                            onChange={(e: any) => {
                                const text: string = e.target.value?.replace(/[-’/`~!#*$@_%+=,^&(){}[\]|;:”<>?\\]/g, "")
                                formRef.setFieldsValue({
                                    code: text,
                                })
                            }}
                            onBlur={(e) => {
                                formRef.setFieldsValue({
                                    code: e.target.value.trim(),
                                })
                            }}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 24}} sm={{span:12}} md={{span: 12}} lg={{span: 8}}>
                    <Form.Item
                        label={trans("product.manufactory")}
                        name="manufactory">
                        <Input
                            maxLength={32}
                            placeholder={trans("product.manufactory_pl")}
                            onBlur={(e) => {
                                formRef.setFieldsValue({
                                    manufactory: e.target.value.trim(),
                                })
                            }}
                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                            disabled={disableEdit}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 24}} sm={{span:12}} md={{span: 12}} lg={{span: 8}}>
                    <Form.Item
                        label={trans("products.group")}
                        name="productGroupCode">
                        <Select
                            disabled={disableEdit}
                            placeholder={trans("product.group_product_pl")}>
                            {listProductGroup?.map((item) => (
                                <Select.Option
                                    key={item.id}
                                    value={item.code}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    xs={{span: 24}} sm={{span:12}} md={{span: 12}} lg={{span: 8}}
                    className="flex">
                    <Form.Item
                        label={trans("campaign.status")}
                        name="isActive"
                        valuePropName="checked">
                        <Switch disabled={disableEdit} />
                    </Form.Item>
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, next) => pre.isActive !== next.isActive}>
                        {({ getFieldValue }) => (
                            <Typography.Text className="mt-9 ml-2">
                                {getFieldValue("isActive") ? trans("user_list.active") : trans("user_list.inactive")}
                            </Typography.Text>
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Typography.Text className="mb-1 block w-full">{trans("product.description")}</Typography.Text>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="description"
                        hidden
                    />
                    <ReactQuill
                        className="w-full h-48 mb-5"
                        theme="snow"
                        readOnly={disableEdit}
                        modules={{ toolbar: toolbarOptions }}
                        placeholder={trans("product.description_pl")}
                        value={formRef.getFieldValue("description")}
                        onChange={(e) => {
                            formRef.setFieldsValue({
                                description: e,
                            })
                        }}
                    />
                </Col>
            </Row>
        </Card>
    )
}
