import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { PAGINATION, payment_statement_BREADCRUMB } from "@config/constant"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { getListPaymentStatement, getStatusPaymentStatement } from "@reducer/paymentStatement.reducer"
import { trans } from "@resources/localization"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { Filter as PaymentStatementFilter } from "./Fitler"
import { ListPaymentStatement } from "./ListPaymentStatement"
import { callVendorsSuggest } from "@reducer/vendor.reducer"

export const PaymentStatement: React.FC = () => {
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()
    useEffect(() => {
        dispatch(changePageTitleAction(trans("sidebar.payment_statement")))
        dispatch(callVendorsSuggest({}))
        dispatch(getStatusPaymentStatement())
    }, [dispatch])
    useEffect(() => {
        const params = Object.fromEntries(searchParams.entries())
        dispatch(
            getListPaymentStatement({
                ...params,
                size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
            })
        )
    }, [dispatch, searchParams])
    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb breadCrumbs={payment_statement_BREADCRUMB} />
            <PaymentStatementFilter />
            <ListPaymentStatement />
        </DefaultLayout>
    )
}
