import React from "react"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { PRODUCT_SCOPE, VENDOR_SCOPE, PAYMENT_STATEMENT_SCOPE } from "./permission"


export interface IMenuItem {
    key: string
    title: string
    icon: JSX.Element
    url: string
    show: boolean
    children?: Array<IMenuItem>
}

export const menuItems: IMenuItem[] = [
    {
        key: "dashboard",
        title: trans("sidebar.dashboard"),
        icon: <i className="fas fa-home" />,
        url: "/",
        show: true
    },
    {
        key: "subscriber",
        title: trans("sidebar.subscriber"),
        icon: <i className="fa fa-users" />,
        url: "/subscriber",
        show: true
    },
    {
        key: "vendor",
        title: trans("sidebar.vendor"),
        icon: <i className="fa-solid fa-handshake" />,
        url: "/vendor",
        show: SecurityService.can(VENDOR_SCOPE.VENDOR_VIEW)
    },
    {
        key: "payment_statement",
        title: trans("sidebar.payment_statement"),
        icon: <i className="fa-solid fa-file-invoice" />,
        url: "/payment-statement",
        show: SecurityService.can(PAYMENT_STATEMENT_SCOPE.PAYMENT_STATEMENT_LIST_VIEW)
    },
    {
        key: "product",
        title: trans("sidebar.product"),
        icon: <i className="fa-solid fa-cube" />,
        url: "/product",
        show: SecurityService.can(PRODUCT_SCOPE.PRODUCT_LIST_VIEW_ALL) || SecurityService.can(PRODUCT_SCOPE.PRODUCT_LIST_VIEW),
    },
]
