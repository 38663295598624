import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

import { PAGINATION } from "@config/constant"
import { PaymentStatementApi } from "@api/PaymentStatementApi"
import { IPaymentStatement, IPaymentStatementQuery, IPaymentStatementRequest, ICurrency, IStatusPaymentStatement, ITypeFee, IUrlUpload } from "@domain/PaymentStatement"

interface State {
    pagination: {
        current: number
        size: number
        total: number
        totalPage: number
    }
    listPaymentStatement: IPaymentStatement[]
    loadingList: boolean
    currenciesSuggest: ICurrency[]
    typeFees: ITypeFee[]
    statuses: IStatusPaymentStatement[],
    dataUrl: IUrlUpload
    loadingUpload: boolean
    loadingCreate: boolean
    loadingGetUrl: boolean
    loadingType: boolean
}

const initState: State = {
    pagination: {
        current: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    listPaymentStatement: [],
    loadingList: false,
    currenciesSuggest: [],
    typeFees: [],
    statuses: [],
    dataUrl: {},
    loadingUpload: false,
    loadingCreate: false,
    loadingGetUrl: false,
    loadingType: false
}

export const getListPaymentStatement = createAsyncThunk("payment_statement.GET_LIST", async (params: IPaymentStatementQuery, thunkApi) => {
    try {
        return await PaymentStatementApi.getListPaymentStatement(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callCurrenciesSuggest = createAsyncThunk("payment_statement.GET_CURRENCIES", async (_, thunkApi) => {
    try {
        return await PaymentStatementApi.getCurrenciesSuggest()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const getTypeFeesOfPaymentStatement = createAsyncThunk("payment_statement.GET_TYPE_FEE", async (_, thunkApi) => {
    try {
        return await PaymentStatementApi.getTypeFeesOfPaymentStatement()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const getStatusPaymentStatement = createAsyncThunk("payment_statement.GET_STATUS_payment_statement", async (_, thunkApi) => {
    try {
        return await PaymentStatementApi.getStatusesPaymentStatement()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const getLinkUrl = createAsyncThunk("payment_statement.GET_LINK_URL", async (params: { fileName: string }, thunkApi) => {
    try {
        return await PaymentStatementApi.getLinkUrlUpload(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callUploadFileBalance = createAsyncThunk("payment_statement.UPLOAD_FILE", async (payload: { url: string, body: any }, thunkApi) => {
    try {
        return await PaymentStatementApi.uploadFileBalance(payload.url, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callCreatePaymentStatement = createAsyncThunk("payment_statement.CREATE_BALANCE", async (payload: IPaymentStatementRequest, thunkApi) => {
    try {
        return await PaymentStatementApi.createPaymentStatement(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const paymentStatementReducer = createReducer(initState, (builder) => {
    builder
        .addCase(getListPaymentStatement.pending, (state) => {
            state.loadingList = true
            state.listPaymentStatement = []
        })
        .addCase(getListPaymentStatement.fulfilled, (state, action) => {
            state.listPaymentStatement = action.payload.data ? action.payload.data : []
            state.pagination.current = action.payload.headers["x-page-number"]
                ? Number(action.payload.headers["x-page-number"])
                : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = action.payload.headers["x-page-size"] ? Number(action.payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = action.payload.headers["x-total-count"] ? Number(action.payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = action.payload.headers["x-page-count"] ? Number(action.payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
        })
        .addCase(getListPaymentStatement.rejected, (state) => {
            state.loadingList = false
            state.listPaymentStatement = []
        })
    builder
        .addCase(callCurrenciesSuggest.pending, (state) => {
            state.currenciesSuggest = []
        })
        .addCase(callCurrenciesSuggest.fulfilled, (state, { payload }) => {
            state.currenciesSuggest = payload.data
        })
        .addCase(callCurrenciesSuggest.rejected, (state) => {
            state.currenciesSuggest = []
        })
    builder
        .addCase(getTypeFeesOfPaymentStatement.pending, (state) => {
            state.typeFees = []
            state.loadingType = true
        })
        .addCase(getTypeFeesOfPaymentStatement.fulfilled, (state, { payload }) => {
            state.typeFees = payload.data
            state.loadingType = false
        })
        .addCase(getTypeFeesOfPaymentStatement.rejected, (state) => {
            state.typeFees = []
            state.loadingType = false
        })
    builder
        .addCase(getStatusPaymentStatement.pending, (state) => {
            state.statuses = []
        })
        .addCase(getStatusPaymentStatement.fulfilled, (state, { payload }) => {
            state.statuses = payload?.data
        })
        .addCase(getStatusPaymentStatement.rejected, (state) => {
            state.statuses = []
        })
    builder
        .addCase(getLinkUrl.pending, (state) => {
            state.dataUrl = {}
            state.loadingGetUrl = true
        })
        .addCase(getLinkUrl.fulfilled, (state, { payload }) => {
            state.dataUrl = payload?.data
            state.loadingGetUrl = false
        })
        .addCase(getLinkUrl.rejected, (state) => {
            state.dataUrl = {}
            state.loadingGetUrl = false
        })
    builder
        .addCase(callUploadFileBalance.pending, (state) => {
            state.loadingUpload = true
        })
        .addCase(callUploadFileBalance.fulfilled, (state) => {
            state.loadingUpload = false
        })
        .addCase(callUploadFileBalance.rejected, (state) => {
            state.loadingUpload = false
        })
    builder
        .addCase(callCreatePaymentStatement.pending, (state) => {
            state.loadingCreate = true
        })
        .addCase(callCreatePaymentStatement.fulfilled, (state) => {
            state.loadingCreate = false
        })
        .addCase(callCreatePaymentStatement.rejected, (state) => {
            state.loadingCreate = false
        })

})
