import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Button, Card, Col, Form, Input, Row, Select, Space } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { forEach, isEmpty, omitBy } from "lodash"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

export const Filter: React.FC = () => {
    const [formRef] = Form.useForm()
    const listGroupProduct = useAppSelector((state) => state.product.listGroupProduct)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const search = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...search,
                ...(searchParams.has("listGroupProduct") && {
                    listGroupProduct: search.listGroupProduct.split(","),
                }),
            })
        } else {
            formRef.resetFields()
        }
    }, [searchParams])

    const handleSubmit = (values: { codeOrName: string; isActive: boolean; productGroupCode: string }) => {
        setSearchParams(() => {
            const oldSearch = Object.fromEntries(searchParams.entries())
            let params: any = {
                ...oldSearch,
                ...Object.fromEntries(searchParams.entries()),
                ...values,
            }
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }
    return (
        <Card className="space-layout">
        <Form
            form={formRef}
            onFinish={handleSubmit}
            layout="vertical">
            <Row gutter={12}>
                <Col xs={{span: 24}} sm={{span:12}} md={{span: 12}} lg={{span: 8}}>
                    <Form.Item
                        label={trans("products.search_name_code")}
                        name="codeOrName">
                        <Input
                            allowClear
                            placeholder={trans("products.search_name_code")}
                            suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)'}} />}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 24}} sm={{span:12}} md={{span: 12}} lg={{span: 8}}>
                    <Form.Item
                        name="productGroupCode"
                        label={trans("products.group")}>
                        <Select
                            allowClear
                            placeholder={trans("products.group")}>
                            {listGroupProduct?.map((item) => (
                                <Select.Option
                                    key={item.id}
                                    value={item.code}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={{span: 24}} sm={{span:12}} md={{span: 12}} lg={{span: 8}}>
                    <Form.Item
                        name="isActive"
                        label={trans("products.status")}>
                        <Select
                            allowClear
                            placeholder={trans("products.status")}>
                            <Select.Option value="false">Inactive</Select.Option>
                            <Select.Option value="true">Active</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Space>
                    <Button
                        type="primary"
                        htmlType="submit">
                        {trans("button.filter")}
                    </Button>
                    <Button
                        type="default"
                        onClick={() => {
                            formRef.resetFields()
                            setSearchParams({})
                        }}>
                        {trans("button.clear_all_filter")}
                    </Button>
                </Space>
            </Row>
        </Form>
        </Card>
    )
}
