import { PAGINATION, regexSpecial } from "@config/constant"
import { IVendorRequest } from "@domain/Vendor"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { callCreateVendor, callListVendor, changeAddVendorVisible } from "@reducer/vendor.reducer"
import { trans } from "@resources/localization"
import { Button, Col, Divider, Form, Input, message, Modal, Row, Select, Space } from "antd"
import React from "react"
import { useSearchParams } from "react-router-dom"
export const CreateVendor: React.FC = () => {
    const [formRef] = Form.useForm()
    const [searchParams] = useSearchParams()
    const isShowAddVendor = useAppSelector((state) => state.vendor.isShowAddVendor)
    const listCountry = useAppSelector((state) => state.vendor.listCountry)
    const loadingCreateVendor = useAppSelector((state) => state.vendor.loadingCreate)
    const dispatch = useAppDispatch()
    const handleSubmit = (values: IVendorRequest) => {
        dispatch(
            callCreateVendor({
                ...values,
                name: values?.name?.trim(),
                code: values?.code?.trim(),
                manager: values?.manager?.trim(),
                clientId: values?.clientId?.trim(),
                clientSecret: values?.clientSecret?.trim(),
            })
        ).then((result: any) => {
            console.log('result', result)
            if (result?.payload?.status === 201) {
                dispatch(
                    callListVendor({
                        ...Object.fromEntries(searchParams.entries()),
                        size: searchParams.has("size") ? Number(searchParams.get("size")) : PAGINATION.DEFAULT_PAGE_SIZE,
                    })
                )
                formRef.resetFields()
                dispatch(changeAddVendorVisible(false))
            } else {
                message.error(trans(`vendor.${result?.payload?.response?.data?.code}`) || trans("message.fail"))
            }
        })
    }
    return (
        <Modal
            title={trans("vendor.create")}
            visible={isShowAddVendor}
            destroyOnClose
            footer={null}
            width={790}
            onCancel={() => {
                formRef.resetFields()
                dispatch(changeAddVendorVisible(false))
            }}>
            <Form
                form={formRef}
                layout="vertical"
                onFinish={handleSubmit}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={trans("vendor.name")}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                                {
                                    validator(_, value) {
                                        if (value && value.trim() !== "") {
                                            if (!regexSpecial.test(value)) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(new Error(trans("message.warning_checking")))
                                        } else if (value && value.trim() === "") {
                                            return Promise.reject(new Error(trans("message.required")))
                                        }
                                        return Promise.resolve()
                                    },
                                },
                            ]}>
                            <Input
                                maxLength={32}
                                placeholder={trans("vendor.name_placeholder")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={trans("vendor.code")}
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                                {
                                    validator(_, value) {
                                        if (value && value.trim() !== "") {
                                            if (!regexSpecial.test(value)) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(new Error(trans("message.warning_checking")))
                                        } else if (value && value.trim() === "") {
                                            return Promise.reject(new Error(trans("message.required")))
                                        }
                                        return Promise.resolve()
                                    },
                                },
                            ]}>
                            <Input
                                maxLength={32}
                                placeholder={trans("vendor.code_placeholder")}
                                onChange={(e) => {
                                    formRef.setFieldsValue({
                                        code: e.target.value?.toLocaleLowerCase(),
                                    })
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={trans("vendor.country")}
                            name="countryCode"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                            ]}>
                            <Select placeholder={trans("placeholder.select_country")}>
                                {listCountry?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.code}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={trans("vendor.administrator")}
                            name="manager"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                                {
                                    validator(_, value) {
                                        if (value && value.trim() === "") {
                                            return Promise.reject(new Error(trans("message.required")))
                                        }
                                        return Promise.resolve()
                                    },
                                },
                            ]}>
                            <Input placeholder={trans("vendor.admin_placeholder")} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={trans("vendor.client_id")}
                            name="clientId"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                                {
                                    validator(_, value) {
                                        if (value && value.trim() === "") {
                                            return Promise.reject(new Error(trans("message.required")))
                                        }
                                        return Promise.resolve()
                                    },
                                },
                            ]}>
                            <Input placeholder={trans("vendor.client_id_placeholder")} />
                        </Form.Item>
                    </Col>
                    <Col span={24} className={"ant-col-display-block "}>
                        <Form.Item
                            label={trans("vendor.client_secret")}
                            name="clientSecret"
                            className="w-full"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                                {
                                    validator(_, value) {
                                        if (value && value.trim() === "") {
                                            return Promise.reject(new Error(trans("message.required")))
                                        }
                                        return Promise.resolve()
                                    },
                                },
                            ]}>
                            <Input placeholder={trans("vendor.client_secret_placeholder")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row justify="end">
                    <Space>
                        <Button
                            type="default"
                            loading={loadingCreateVendor}
                            onClick={() => {
                                formRef.resetFields()
                                dispatch(changeAddVendorVisible(false))
                            }}>
                            {trans("button.cancel")}
                        </Button>
                        <Button
                            loading={loadingCreateVendor}
                            htmlType="submit"
                            type="primary">
                            {trans("button.add")}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
